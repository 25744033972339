import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

const patientGenePanels = {
  count: 4,
  gene_panels: [
    {
      archived: 0,
      base_panel_title: null,
      project_id: PROJECT_ID_RARE_DISEASE,
      version: "1.0",
      is_patient_gene_panel: 0,
      external_id: null,
      description: "Inherited panel old version",
      gene_panel_id: 2,
      title: "Inherited panel old version",
    },
    {
      archived: 0,
      base_panel_title: null,
      project_id: PROJECT_ID_RARE_DISEASE,
      version: "1.0",
      is_patient_gene_panel: 0,
      external_id: null,
      description: "File import: GPM.cerebellar_hypoplasia.txt",
      gene_panel_id: 3,
      title: "GPM.cerebellar_hypoplasia",
    },
    {
      archived: 1,
      base_panel_title: null,
      project_id: PROJECT_ID_RARE_DISEASE,
      version: "1.0",
      description: null,
      is_patient_gene_panel: 1,
      external_id: null,
      gene_panel_id: 933,
      title: "PGx genes: Demo - Archived Panel",
    },
    {
      archived: 0,
      base_panel_title: null,
      project_id: PROJECT_ID_RARE_DISEASE,
      version: "1.0",
      is_patient_gene_panel: 1,
      description: null,
      ensembl_version: "108",
      external_id: null,
      gene_panel_id: 7,
      title: "PCD: Demo",
    },
  ],
};

export default patientGenePanels;
