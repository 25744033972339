export const SYSTEM_ENSEMBL_VERSION = "108";
export const PREV_SYSTEM_ENSEMBL_VERSION = "81";
export const ROOT_SNV_FILTER_PRESET_NAME = "Root SNV Preset";
export const ROOT_SV_FILTER_PRESET_NAME = "Root SV Preset";

export const PATIENT_ID_RD_ENSEMBL_81 = 525;
export const PATIENT_ID_RD_NO_ANNOTATION_SOURCES = 100;

export const PATIENT_ID_ONCOLOGY_READY_FOR_REVIEW = 10919;
export const PATIENT_ID_ONCOLOGY_QC_CHECKED = 10918;
export const PATIENT_ID_ONCOLOGY_REPORT_CHECKED = 10917;
export const PATIENT_ID_ONCOLOGY_REVIEW_COMPLETE = 10916;
export const PATIENT_ID_ONCOLOGY_WITHOUT_QC_DATA = 10915;

/**
 * ID of the Rare Disease project that has most happy paths, and
 * is the primary project used for testing/mocked scenarios
 */
export const PROJECT_ID_RARE_DISEASE = 7;
/**
 * ID of the Rare Disease project that has minimal objects/entities,
 * e.g. just one automation CVL
 */
export const PROJECT_ID_RARE_DISEASE_MINIMAL = 1000;
export const PROJECT_ID_ONCOLOGY = 942;

export const USER_ID_SYSTEM_USER = 425;

export const AUTH_PROVIDER_1_NAME = "Mock Authentication Provider Name";

export const CVL_ID_INHERITED = 9;
export const CVL_NAME_INHERITED = "Caminsky validated splices";
export const CVL_ID_PROJECT_OWN = 159;
export const CVL_NAME_PROJECT_OWN = "Auto 32";
export const CVL_ID_PROJECT_OWN_REFERENCE = 23;
export const CVL_ID_INHERITED_REFERENCE = 24;
export const CVL_ID_NOT_ELIGIBLE_FOR_AUTOMATION = 161;
export const CVL_NAME_NOT_ELIGIBLE_FOR_AUTOMATION = "Auto outdated";
export const CVL_NAME_ELIGIBLE_FOR_AUTOMATION_IN_LOADING_STATE = "Auto loading";
export const CVL_ID_ELIGIBLE_FOR_AUTOMATION_IN_LOADING_STATE = 163;

/**
 * Gene symbol/name that is present in the mock fixtures
 */
export const GENE_NAME_PRESENT = "AARS2";

/**
 * Gene symbol/name that is not present in the mock fixtures (in any Ensembl version)
 */
export const GENE_NAME_NOT_PRESENT = "SOMERANDOMGENE";
