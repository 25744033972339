export const mockVersions = [
  {
    value: "81",
    name: "ensembl",
    original_build_id: null,
    label: "Ensembl",
    updated: 1654010252,
    build_id: 26,
    version: null,
    version_info_id: 272,
    created: 1445261785,
    position: 1,
  },
  {
    original_build_id: null,
    name: "assembly",
    value: "GRCh37",
    build_id: 26,
    label: "Assembly",
    updated: 1653566494,
    version: null,
    created: 1445261785,
    version_info_id: 269,
    position: 2,
  },
  {
    name: "hpo",
    original_build_id: null,
    value: "#1702 (September 3, 2016)",
    build_id: 26,
    updated: 1653566494,
    label: "HPO",
    version: null,
    created: 1445261785,
    version_info_id: 270,
    position: 3,
  },
  {
    updated: 1655374751,
    label: "VEP",
    build_id: 26,
    value: "Ensembl 81",
    original_build_id: null,
    name: "vep",
    position: 4,
    version: null,
    version_info_id: 271,
    created: 1445261785,
  },
  {
    value: "2016.2",
    name: "hgmd",
    original_build_id: null,
    build_id: 26,
    updated: 1469692970,
    label: "HGMD",
    version: null,
    version_info_id: 273,
    created: 1445261785,
    position: 5,
  },
  {
    version: null,
    version_info_id: 274,
    created: 1445261785,
    position: 6,
    value: "v0.3",
    name: "exac",
    original_build_id: null,
    updated: 1623842012,
    build_id: 26,
    label: "ExAC",
  },
  {
    updated: 1454947213,
    build_id: 26,
    label: "UK10K",
    value: "UK10K TWINS release",
    original_build_id: null,
    name: "uk10k",
    position: 7,
    version_info_id: 275,
    created: 1445261785,
    version: null,
  },
  {
    created: 1445261785,
    version_info_id: 268,
    version: null,
    position: 8,
    original_build_id: null,
    name: "1k",
    value: "Phase 1 data",
    build_id: 26,
    updated: 1467635421,
    label: "1000",
  },
  {
    version: null,
    created: 1445261785,
    version_info_id: 267,
    position: 9,
    original_build_id: null,
    name: "evs",
    value: "ESP6500SI-V2 (evs.gs.washington.edu/EVS/)",
    build_id: 26,
    label: "EVS",
    updated: 1467635421,
  },
];

// Mapping from project ID -> response
export default {
  "7": mockVersions,
};
