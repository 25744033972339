const software = {
  data: [
    {
      type: "thirdparty_software",
      attributes: { version: "1.0", name: "SSF", is_active: true },
      id: "1",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "4.10.0_4", name: "MAST", is_active: true },
      id: "2",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "1.0", name: "DBSC_SNV", is_active: true },
      id: "3",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "1.1", name: "MAXENTSCAN", is_active: true },
      id: "4",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "1.3.1", name: "SPLICE_AI", is_active: true },
      id: "5",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "12.1.0", name: "EXOMISER", is_active: true },
      id: "6",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "v5.4", name: "CONGENICA_AI", is_active: true },
      id: "8",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "81", name: "VEP", is_active: false },
      id: "9",
    },
    {
      type: "thirdparty_software",
      attributes: { version: "108", name: "VEP", is_active: true },
      id: "10",
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/active_thirdparty_software_versions",
  },
  meta: { count: 8 },
  jsonapi: { version: "1.0" },
};

export default software;
