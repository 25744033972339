import { set } from "dot-prop-immutable";

import {
  CVL_ID_NOT_ELIGIBLE_FOR_AUTOMATION,
  PROJECT_ID_RARE_DISEASE,
} from "tests/fixtures";

export const automationDisabledPayload = {
  is_inherited: false,
  settings: {
    enabled: false,
    ref_no_call_enabled: false,
    moi_restriction_enabled: false,
    automated_reporting_enabled: false,
    report_template_id: null,
    curated_variant_list_id: null,
  },
};

export const automationPayload = {
  is_inherited: false,
  settings: {
    enabled: true,
    ref_no_call_enabled: false,
    moi_restriction_enabled: true,
    automated_reporting_enabled: true,
    report_template_id: "fabd15a6-7afc-11ea-a0ab-ed180b69db89",
    curated_variant_list_id: 159,
  },
};

export const autoAcmgPayload = {
  is_inherited: false,
  settings: {
    enabled: true,
  },
};

export const featuresSettingsPayloadAttributes = {
  automation: automationPayload,
  auto_acmg: autoAcmgPayload,
};

export const featuresSettingsAutomationPayloadAttributes = {
  automation: automationPayload,
  auto_acmg: undefined,
};

export const featuresSettingsAutoAcmgPayloadAttributes = {
  auto_acmg: autoAcmgPayload,
  automation: undefined,
};

export const featuresSettingsPayload = {
  data: {
    attributes: featuresSettingsPayloadAttributes,
    id: PROJECT_ID_RARE_DISEASE,
    type: "multiple_project_feature_settings",
  },
};

export const featuresSettingsAutomationPayload = {
  data: {
    attributes: featuresSettingsAutomationPayloadAttributes,
    id: PROJECT_ID_RARE_DISEASE,
    type: "multiple_project_feature_settings",
  },
};

export const featuresSettingsAutoAcmgPayload = {
  data: {
    attributes: featuresSettingsAutoAcmgPayloadAttributes,
    id: PROJECT_ID_RARE_DISEASE,
    type: "multiple_project_feature_settings",
  },
};

export const automationSettings = {
  isInherited: false,
  enabled: true,
  moiRestrictionEnabled: true,
  automatedReportingEnabled: true,
  curatedVariantListId: 159,
  refNoCallEnabled: false,
  reportTemplateId: "fabd15a6-7afc-11ea-a0ab-ed180b69db89",
};

export const autoAcmgFeatureSettings = {
  isInherited: false,
  enabled: true,
};

export const featuresSettings = {
  automation: automationSettings,
  autoAcmg: autoAcmgFeatureSettings,
};

export const featuresSettingsAutomation = {
  automation: automationSettings,
};

export const featuresSettingsAutoAcmg = {
  autoAcmg: autoAcmgFeatureSettings,
};

export const featureSettingsAutomationDisabled = set(
  featuresSettingsPayload,
  "data.attributes.automation",
  automationDisabledPayload
);

export const featureSettingsAutomationOutdatedCVL = set(
  featuresSettingsPayload,
  "data.attributes.automation.settings.curated_variant_list_id",
  CVL_ID_NOT_ELIGIBLE_FOR_AUTOMATION
);
