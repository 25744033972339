import { rest } from "msw";
import type { ResponseComposition, RestContext, RestRequest } from "msw";

import { response as geneResponse } from "tests/fixtures/perl/gene/mock-gene-response";
import geneSearchResponse from "tests/fixtures/perl/gene/search/mock-gene-search";
import variantHistoryResponse from "tests/fixtures/perl/patient/id/variant/variantId/gene/geneName/audit";
import fastqcJson from "tests/fixtures/perl/patient/qc/fastq/mock-fastq-json";
import vcfJson from "tests/fixtures/perl/patient/qc/vcf/mock-vcf-json";
import mockExistingUserDetails from "tests/fixtures/perl/project/admin/user/mock-existing-user-details";
import mockExistingPanels from "tests/fixtures/perl/project/gene-panels/add/copy/mock-existing-gene-panels";
import mockGenePanelGenes from "tests/fixtures/perl/project/gene-panels/add/upload/genes/mock-gene-panel-genes";
import mockGenePanelGenesNotFound from "tests/fixtures/perl/project/gene-panels/add/upload/genes/not/found/mock-gene-panel-genes-not-found";
import mockGenePanelInfo from "tests/fixtures/perl/project/gene-panels/add/upload/info/mock-gene-panel-upload-info";
import mockTempGenePanelId from "tests/fixtures/perl/project/gene-panels/add/upload/react/mock-gene-panel-upload-temp-id";
import { genePanelWithPatientResponse } from "tests/fixtures/perl/project/gene-panels/mock-gene-panel";
import { response as projectGenePanelsResponse } from "tests/fixtures/perl/project/gene-panels/mock-project-gene-panels";
import editGeneResponse from "tests/fixtures/perl/project/id/gene-panel/id/gene/id/edit/mock-edit-gene";
import genePanelGenesResponse from "tests/fixtures/perl/project/id/gene-panel/id/genes/list/mock-gene-panel-genes";
import genePanelPatientsResponse from "tests/fixtures/perl/project/id/gene-panel/id/patients/list/mock-gene-panel-patients";
import { mockProjectIRsResponse } from "tests/fixtures/perl/project/interpretation-requests/mock-irs";
import mockVersions from "tests/fixtures/perl/project/versions/mock-versions";

const legacyAcceptHeader = "application/vnd.congenica.json";

const forceAcceptHeader = (
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
  json: unknown
) => {
  if (req.headers.get("accept") !== legacyAcceptHeader)
    return res(ctx.text("<body>some legacy html response</body>"));

  return res(ctx.json(json));
};

const responseWithSuccessHeaders = (
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext
) => res(ctx.status(200), ctx.set("X-Message-Success", "Ok"));

export default [
  // This is only in place for the LegacySapientiaTemplate tests - this exact endpoint is not used in practice,
  // but this is similar to how the legacy HTML endopints do behave
  rest.get("/tests-only/legacy-endpoint", (req, res, ctx) =>
    res(ctx.text("<div>Some legacy content</div>"))
  ),

  rest.get("/project/:projectId/interpretation-requests", (req, res, ctx) => {
    if (req.params.projectId !== "1") return res(ctx.status(404));
    return res(ctx.json(mockProjectIRsResponse));
  }),

  rest.get("/project/:projectId/versions", (req, res, ctx) => {
    const { projectId } = req.params;
    const versions = mockVersions[projectId as string];
    if (!versions) return res(ctx.status(400));
    return forceAcceptHeader(req, res, ctx, versions);
  }),

  rest.post("/patient/:patientId/report/preview", (req, res, ctx) =>
    res(ctx.status(200))
  ),

  rest.get("/patient/:patientId/report/view/:reportId", (req, res, ctx) =>
    res(ctx.status(200))
  ),

  rest.get("/patient/:patientId/qc/fastqc/json", (req, res, ctx) =>
    res(ctx.json(fastqcJson))
  ),

  rest.get("/patient/:patientId/qc/vcf/json", (req, res, ctx) =>
    res(ctx.json(vcfJson))
  ),

  rest.get(
    "/patient/:patientId/variant/:variantId/gene/:geneName/audit.json",
    (req, res, ctx) => res(ctx.json(variantHistoryResponse))
  ),

  rest.get("/project/:projectId/gene-panels", (req, res, ctx) => {
    const showArchivedFilter = req.url.searchParams.get("show_archived");
    const { gene_panels, inherited_gene_panels } = projectGenePanelsResponse;
    if (showArchivedFilter === "1") {
      return res(ctx.json(projectGenePanelsResponse));
    }
    return res(
      ctx.json({
        inherited_gene_panels: inherited_gene_panels.filter(
          ({ archived }) => !archived
        ),
        gene_panels: gene_panels.filter(({ archived }) => !archived),
      })
    );
  }),

  rest.post(
    "/project/:projectId/gene-panel/add/new",
    responseWithSuccessHeaders
  ),

  rest.get("/project/:projectId/gene-panel/add/copy", (req, res, ctx) =>
    forceAcceptHeader(req, res, ctx, mockExistingPanels)
  ),

  rest.post(
    "/project/:projectId/gene-panel/add/:genePanelId",
    (req, res, ctx) =>
      // Legacy endpoint returns text/HTML, even if the header is sent
      res(ctx.text("<em>Gene panel added</em>"))
  ),

  rest.post(
    "/project/:projectId/gene-panel/add/upload/react",
    (req, res, ctx) => res(ctx.json(mockTempGenePanelId))
  ),

  rest.get(
    "/project/:projectId/gene-panel/add/upload/:tempPanelId/info",
    (req, res, ctx) => res(ctx.json(mockGenePanelInfo))
  ),

  rest.post(
    "/project/:projectId/gene-panel/:tempPanelId/info",
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.get(
    "/project/:projectId/gene-panel/add/upload/:tempPanelId/genes",
    (req, res, ctx) => res(ctx.json(mockGenePanelGenes))
  ),

  rest.get(
    "/project/:projectId/gene-panel/add/upload/:tempPanelId/genes/not/found",
    (req, res, ctx) => res(ctx.json(mockGenePanelGenesNotFound))
  ),

  rest.get("/project/:projectId/gene-panel/:genePanelId", (req, res, ctx) =>
    res(ctx.json(genePanelWithPatientResponse))
  ),

  rest.get(
    "/project/:projectId/gene-panel/:genePanelId/patients/list",
    (req, res, ctx) => res(ctx.json(genePanelPatientsResponse))
  ),

  rest.get(
    "/project/:projectId/gene-panel/:genePanelId/genes/list",
    (req, res, ctx) => res(ctx.json(genePanelGenesResponse))
  ),

  rest.get(
    "/project/:projectId/gene-panel/:genePanelId/gene/:geneId/edit",
    (req, res, ctx) => res(ctx.json(editGeneResponse))
  ),

  rest.post(
    "/project/:projectId/gene-panel/:genePanelId/gene/:geneId/edit",
    responseWithSuccessHeaders
  ),

  rest.post(
    "/project/:projectId/gene-panel/:genePanelId/gene/:geneId/add",
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.post(
    "/project/:projectId/gene-panel/:genePanelId/gene/:geneId/delete",
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.get("/gene/search", (req, res, ctx) =>
    res(ctx.json(geneSearchResponse))
  ),

  rest.get("/gene/:geneName", (req, res, ctx) => res(ctx.json(geneResponse))),

  rest.get("/project/:projectId/admin/user/add/existing", (req, res, ctx) =>
    res(ctx.status(200))
  ),

  rest.post(
    "/project/:projectId/gene-panel/:genePanelId/patient/:patientId/add",
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.post(
    "/project/:projectId/gene-panel/:genePanelId/patient/:patientId/delete",
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.get(
    "/project/:projectId/admin/user/:userId/add_permissions",
    (req, res, ctx) => {
      const { userId } = req.params;
      const existingUserDetails = mockExistingUserDetails[userId as string];
      if (!existingUserDetails) return res(ctx.status(400));
      return res(ctx.json(existingUserDetails));
    }
  ),

  rest.post("/project/:projectId/admin/user/add", (req, res, ctx) =>
    res(ctx.status(200))
  ),

  rest.post("/project/:projectId/admin/user/:userId/edit", (req, res, ctx) =>
    res(ctx.status(200))
  ),
  rest.post(
    "/project/:projectId/admin/user/:userId/add_permissions",
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.post("/project/:projectId/admin/user/:userId/delete", (req, res, ctx) =>
    res(ctx.status(200))
  ),
];
