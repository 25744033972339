const mockOncologyCopyNumberVariants = {
  data: [
    {
      type: "oncology_cnv_variants",
      attributes: {
        score: 3.476,
        l_0: 1865496,
        h_0: 1869432,
        gene_type: "ONC",
        chrom: "chrX",
        gene: "ERBB2",
        variant_type: "amplification",
      },
      id: "ERBB2 Amplification",
    },
    {
      type: "oncology_cnv_variants",
      attributes: {
        score: 1.242727,
        l_0: 55019274,
        h_0: 55205618,
        gene_type: "ONC",
        chrom: "chr7",
        gene: "EGFR",
        variant_type: "amplification",
      },
      id: "EGFR Amplification",
    },
    {
      type: "oncology_cnv_variants",
      attributes: {
        score: 0.9281209,
        l_0: 4367124,
        h_0: 4381621,
        gene_type: "ONC",
        chrom: "chr12",
        gene: "FGF23",
        variant_type: "amplification",
      },
      id: "FGF23 Amplification",
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/patients/14981/oncology/variants/cnvs",
  },
  meta: {
    count: 3,
  },
  jsonapi: {
    version: "1.0",
  },
};

export default mockOncologyCopyNumberVariants;
