import { response as oncologyProjectPatients } from "./oncology-patients";
import { response as rareDiseaseProjectPatients } from "./rd-patients";

import { PROJECT_ID_ONCOLOGY, PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

const projectIdMap = {
  [PROJECT_ID_ONCOLOGY]: oncologyProjectPatients,
  [PROJECT_ID_RARE_DISEASE]: rareDiseaseProjectPatients,
};

export default projectIdMap;
