const mockRoles = {
  data: [
    {
      type: "project_roles",
      attributes: {
        name: "approver",
        label: "Second analyst",
        updated: 1644564137,
        only_double_user_check: true,
        created: 1445537547,
        user_project_access_level: null,
        lookup_id: "approve",
        project_type_internal_name: "rare_disease",
        description: "approves decisions made by the first analyst",
      },
      id: 2,
    },
    {
      type: "project_roles",
      attributes: {
        name: "assigner",
        label: "Analyst",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1445537547,
        user_project_access_level: null,
        lookup_id: "assign",
        project_type_internal_name: "rare_disease",
        description:
          "assigns pathogenicity and contribution to phenotype of the variants",
      },
      id: 1,
    },
    {
      type: "project_roles",
      attributes: {
        name: "authoriser",
        label: "Authoriser",
        updated: 1644564137,
        only_double_user_check: true,
        created: 1466703665,
        user_project_access_level: null,
        lookup_id: "authorise",
        project_type_internal_name: "rare_disease",
        description: "performs a final report sign off",
      },
      id: 3,
    },
    {
      type: "project_roles",
      attributes: {
        name: "curator",
        label: "Clinical data curator",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1496789235,
        user_project_access_level: "write",
        lookup_id: "write",
        project_type_internal_name: "rare_disease",
        description:
          "creates patient records and modifies clinical information",
      },
      id: 4,
    },
    {
      type: "project_roles",
      attributes: {
        name: "gene_panel_curator",
        label: "Gene Panel Curator",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1564484713,
        user_project_access_level: null,
        lookup_id: "genecurator",
        project_type_internal_name: "rare_disease",
        description: "Can add and edit gene panels",
      },
      id: 5,
    },
    {
      type: "project_roles",
      attributes: {
        name: "complete_review",
        label: "Approve report",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1644564137,
        user_project_access_level: null,
        lookup_id: "complete_review",
        project_type_internal_name: "oncology",
        description: "Complete review and approve final PDF",
      },
      id: 16,
    },
    {
      type: "project_roles",
      attributes: {
        name: "submit_ir",
        label: "Submit an Interpretation Request",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1644564137,
        user_project_access_level: null,
        lookup_id: "submit_ir",
        project_type_internal_name: "oncology",
        description:
          "Upload patient sample information to trigger processing of data",
      },
      id: 12,
    },
    {
      type: "project_roles",
      attributes: {
        name: "check_qc",
        label: "Perform QC review",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1644564137,
        user_project_access_level: null,
        lookup_id: "check_qc",
        project_type_internal_name: "oncology",
        description: "Review QC metrics and coverage for QC check",
      },
      id: 13,
    },
    {
      type: "project_roles",
      attributes: {
        name: "check_report",
        label: "Perform report review",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1644564137,
        user_project_access_level: null,
        lookup_id: "check_report",
        project_type_internal_name: "oncology",
        description: "Review report contents",
      },
      id: 14,
    },
    {
      type: "project_roles",
      attributes: {
        name: "create_report_summary",
        label: "Write report summary",
        updated: 1644564137,
        only_double_user_check: false,
        created: 1644564137,
        user_project_access_level: null,
        lookup_id: "create_report_summary",
        project_type_internal_name: "oncology",
        description: "Summarise report in free text for provider",
      },
      id: 15,
    },
  ],
  meta: { count: 10 },
};

export default mockRoles;
