import {
  PREV_SYSTEM_ENSEMBL_VERSION,
  SYSTEM_ENSEMBL_VERSION,
} from "tests/fixtures";

const data = {
  data: [
    {
      type: "thirdparty_data",
      attributes: { version: "2017-12-12", name: "HPO_DB", is_active: true },
      id: "2",
    },
    {
      type: "thirdparty_data",
      attributes: {
        version: "2020-12-07",
        name: "HPO_EXOMISER",
        is_active: true,
      },
      id: "5",
    },
    {
      type: "thirdparty_data",
      attributes: { version: "2102", name: "EXOMISER", is_active: true },
      id: "6",
    },
    {
      type: "thirdparty_data",
      attributes: { version: "v5.4", name: "CONGENICA_AI", is_active: true },
      id: "8",
    },
    {
      type: "thirdparty_data",
      attributes: {
        version: "2.1.1",
        name: "GNOMAD_GENE_CONSTRAINT_SCORES",
        is_active: true,
      },
      id: "9",
    },
    {
      type: "thirdparty_data",
      attributes: { version: "0.3", name: "EXAC_EXOMES", is_active: true },
      id: "10",
    },
    {
      type: "thirdparty_data",
      attributes: {
        version: PREV_SYSTEM_ENSEMBL_VERSION,
        name: "ENSEMBL",
        is_active: false,
      },
      id: "11",
    },
    {
      type: "thirdparty_data",
      attributes: {
        version: SYSTEM_ENSEMBL_VERSION,
        name: "ENSEMBL",
        is_active: true,
      },
      id: "12",
    },
    {
      type: "thirdparty_data",
      attributes: {
        version: SYSTEM_ENSEMBL_VERSION,
        name: "VEP_CACHE",
        is_active: true,
      },
      id: "13",
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/thirdparty_data_versions",
  },
  meta: { count: 8 },
  jsonapi: { version: "1.0" },
};

export default data;
