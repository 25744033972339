import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

export const response = {
  data: [
    {
      type: "project_patient_metadata_field",
      attributes: {
        required: false,
        project_id: PROJECT_ID_RARE_DISEASE,
        source_project_code: "Demo",
        patient_metadata_field_id: 304,
        created: 1466846705,
        visible_in_list: true,
        field_code: "allocated_to",
        field_name: "Allocated to",
        position: 1,
        field_type: "Text",
        source_project_id: PROJECT_ID_RARE_DISEASE,
        inherited: false,
        updated: 1605904068,
        project_code: "Demo",
        category_name: null,
      },
      id: 304,
    },
    {
      type: "project_patient_metadata_field",
      attributes: {
        required: false,
        project_id: PROJECT_ID_RARE_DISEASE,
        source_project_code: "Demo",
        patient_metadata_field_id: 576,
        created: 1471265101,
        visible_in_list: true,
        field_code: "person_id",
        field_name: "Person Id",
        position: 4,
        field_type: "Text",
        source_project_id: PROJECT_ID_RARE_DISEASE,
        inherited: false,
        updated: 1605904882,
        project_code: "Demo",
        category_name: null,
      },
      id: 576,
    },
    {
      type: "project_patient_metadata_field",
      attributes: {
        required: true,
        project_id: PROJECT_ID_RARE_DISEASE,
        source_project_code: "Demo",
        patient_metadata_field_id: 572,
        created: 1470910132,
        visible_in_list: true,
        field_code: "ETH",
        field_name: "Ethnicity ",
        position: 6,
        field_type: "Select::Ethnicity",
        source_project_id: PROJECT_ID_RARE_DISEASE,
        inherited: false,
        updated: 1605904882,
        project_code: "Demo",
        category_name: null,
      },
      id: 572,
    },
    {
      type: "project_patient_metadata_field",
      attributes: {
        required: false,
        project_id: PROJECT_ID_RARE_DISEASE,
        source_project_code: "Demo",
        patient_metadata_field_id: 577,
        created: 1471265128,
        visible_in_list: true,
        field_code: "person_type",
        field_name: "Person Type",
        position: 7,
        field_type: "Text",
        source_project_id: PROJECT_ID_RARE_DISEASE,
        inherited: false,
        updated: 1605904882,
        project_code: "Demo",
        category_name: null,
      },
      id: 577,
    },
    {
      type: "project_patient_metadata_field",
      attributes: {
        required: false,
        project_id: PROJECT_ID_RARE_DISEASE,
        source_project_code: "Demo",
        patient_metadata_field_id: 577,
        created: 1471265128,
        visible_in_list: true,
        field_code: "reference",
        field_name: "reference",
        position: 7,
        field_type: "Text",
        source_project_id: PROJECT_ID_RARE_DISEASE,
        inherited: false,
        updated: 1605904882,
        project_code: "Demo",
        category_name: null,
      },
      id: 345,
    },
    {
      type: "project_patient_metadata_field",
      attributes: {
        required: false,
        project_id: PROJECT_ID_RARE_DISEASE,
        source_project_code: "Demo",
        patient_metadata_field_id: 601,
        created: 1605797015,
        visible_in_list: true,
        field_code: "test_date_1",
        field_name: "test date 1",
        position: 11,
        field_type: "Date",
        source_project_id: PROJECT_ID_RARE_DISEASE,
        inherited: false,
        updated: 1606275428,
        project_code: "Demo",
        category_name: null,
      },
      id: 601,
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/projects/7/patient_metadata_fields?filter%5Bvisible_in_list%5D=true",
  },
  meta: { count: 9 },
  jsonapi: { version: "1.0" },
};
