const defaultResponse = {
  can_admin_project: true,
  features: {
    auto_acmg: true,
    automation: true,
  },
  permissions: {
    add_comments_flag_literature: true,
    add_curated_variant_list: true,
    add_edit_gene_panels: true,
    add_edit_users: true,
    add_patient: true,
    add_projects: true,
    add_variant_to_curated_list: true,
    approve_reject_decisions: true,
    assign_gene_panels_to_patients: true,
    assign_pathogenicity: true,
    assign_patient_to_gene_panels: true,
    authorise_decisions: true,
    edit_acmg_guideline_evidence: true,
    edit_patient: true,
    edit_patient_visibility: true,
    generate_patient_reports: true,
    perform_primary_review: true,
    perform_secondary_review: true,
    set_project_defaults: true,
    set_project_variant_filter_and_prioritisation: true,
    upload_supporting_material: true,
  },
  roles: {
    approver: true,
    assigner: true,
    authoriser: true,
    curator: true,
    gene_panel_curator: true,
  },
  sample_upload: true,
  tabs: {
    automation: true,
    cvl: true,
  },
};

export default defaultResponse;
