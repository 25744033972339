import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

const existingListsAvailabilityResponse = {
  data: {
    type: "project_unrelated_cvl_exist",
    attributes: { exist: true },
    id: PROJECT_ID_RARE_DISEASE,
  },
  jsonapi: { version: "1.0" },
};

export default existingListsAvailabilityResponse;
