export const mockReportProposalLatest = {
  data: {
    type: "report_proposal",
    attributes: {
      reportServiceTemplateUuid: "77be62d4-de4e-11eb-8f56-612548a3cc7b",
      reportSummary: "TEST SUMMARY 1",
      jurisdiction: "EU",
    },
    id: 92,
  },
  jsonapi: {
    version: "1.0",
  },
};
