import { SYSTEM_ENSEMBL_VERSION } from "tests/fixtures";

export const genePanelWithPatientResponse = {
  gene_panel: {
    description: null,
    title: "Mitochondrial genes",
    genes_count: 3,
    gene_panel_id: 2810,
    patient_count: 0,
    ensembl_version: SYSTEM_ENSEMBL_VERSION,
  },
};
