const existingListsResponse = {
  data: [
    {
      attributes: {
        description: null,
        name: "Demo Project black list",
        variant_type: "SNV",
        is_automated_annotation_decisions: 0,
        is_reference: false,
      },
      id: "91",
    },
  ],
  meta: { count: 1 },
};

export default existingListsResponse;
