export const response = {
  project: {
    code: "Oncology Project",
    patient_reference_label: null,
    is_patient_visibility_enabled: 1,
    users_count: 8,
    description: "Oncology Project",
    patients_count: 32,
    descendants_count: 0,
    project_id: 942,
    children_count: 0,
    is_double_user_check_enabled: 0,
  },
  patients: [
    {
      sample_type_name: null,
      has_auto_acmg_suggestions: 0,
      reports_with_variants_count: 0,
      metadata_values: null,
      family_refs: null,
      baitset: "agilent_v1_0425761",
      gene_panel_titles: null,
      cnv_analysis: "Not requested",
      reference: "neoplasticCellularity_1",
      has_automated_annotation: 0,
      tumour_type: {
        oncotree_code: "PAAC",
        name: "Acinar Cell Carcinoma of the Pancreas",
      },
      has_reports: 0,
      reports_without_variants_count: 0,
      phenotype_names: null,
      protocol_name: null,
      updated: 1649156026,
      neoplasticCellularity: "56",
      patient_id: 10975,
      has_some_obsolete_phenotypes: 0,
      reports_count: 0,
      status: "Ready to process",
      phenotype_summary: null,
    },
    {
      has_automated_annotation: 0,
      cnv_analysis: "Not requested",
      reference: "smpl7",
      gene_panel_titles: null,
      baitset: "core_v1_0425761",
      phenotype_names: null,
      reports_without_variants_count: 0,
      has_reports: 0,
      tumour_type: {
        name: "Colorectal Adenocarcinoma",
        oncotree_code: "COADREAD",
      },
      reports_with_variants_count: 0,
      has_auto_acmg_suggestions: 0,
      sample_type_name: null,
      family_refs: null,
      metadata_values: null,
      status: "Ready to process",
      phenotype_summary: null,
      patient_id: 10934,
      neoplasticCellularity: "44",
      protocol_name: null,
      updated: 1634202835,
      reports_count: 0,
      has_some_obsolete_phenotypes: 0,
    },
  ],
  metadata: [{ name: "Allocated to", id: 304 }],
};
