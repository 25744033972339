import { setupWorker, rest } from "msw";

import handlers from "./handlers";

// Fall back to actual server when running as a service worker, for local development
const passthroughHandler = rest.all("*", req => {
  if (
    !req.url.toString().match(new RegExp("/static/|hot-update|maxcdn|cdnjs"))
  ) {
    // eslint-disable-next-line no-console
    console.info(`MSW: passing request through: ${req.url}`);
  }
  return req.passthrough();
});

export default setupWorker(...handlers, passthroughHandler);
