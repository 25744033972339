import { response as oncology } from "./oncology-project";
import { response as rareDisease } from "./rd-project";

import { PROJECT_ID_ONCOLOGY, PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

const projectIdMap = {
  [PROJECT_ID_ONCOLOGY]: oncology,
  [PROJECT_ID_RARE_DISEASE]: rareDisease,
};

export default projectIdMap;
