export const response = {
  inherited_gene_panels: [
    {
      archived: 0,
      base_panel_title: null,
      description: null,
      ensembl_version: "108",
      gene_panel_id: 1,
      genes_count: 20,
      patients_count: 10,
      title: "Inherited panel latest version",
      total_patients_count: 10,
      updated: 1445856341,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "Inherited panel old version",
      ensembl_version: "81",
      gene_panel_id: 2,
      genes_count: 17,
      patients_count: 4,
      title: "Inherited panel old version",
      total_patients_count: 4,
      updated: 1454432576,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: GPM.cerebellar_hypoplasia.txt",
      ensembl_version: "108",
      gene_panel_id: 3,
      genes_count: 53,
      patients_count: 5,
      title: "GPM.cerebellar_hypoplasia",
      total_patients_count: 5,
      updated: 1454433562,
    },
    {
      archived: 0,
      base_panel_title: null,
      description:
        "Top exomiser genes given sample vcf and disease-id 277470 (Pontocerebellar hypoplasia)",
      ensembl_version: "108",
      gene_panel_id: 4,
      genes_count: 230,
      patients_count: 2,
      title: "Some other inherited panel",
      total_patients_count: 2,
      updated: 1454515046,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: misc inherited panel",
      ensembl_version: "108",
      gene_panel_id: 5,
      genes_count: 127,
      patients_count: 6,
      title: "Inherited panel foo",
      total_patients_count: 6,
      updated: 1454519440,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: Another inherited panel.txt",
      ensembl_version: "108",
      gene_panel_id: 6,
      genes_count: 1440,
      patients_count: 3,
      title: "Another inherited panel",
      total_patients_count: 3,
      updated: 1454522742,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: null,
      ensembl_version: "108",
      gene_panel_id: 7,
      genes_count: 41,
      patients_count: 1,
      title: "PCD: Demo",
      total_patients_count: 1,
      updated: 1412178620,
    },
  ],
  gene_panels: [
    {
      archived: 1,
      base_panel_title: null,
      description: null,
      ensembl_version: "81",
      gene_panel_id: 933,
      genes_count: 20,
      patients_count: 10,
      title: "PGx genes: Demo - Archived Panel",
      total_patients_count: 10,
      updated: 1445856341,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: Cerebellar_hypoplasia.txt",
      ensembl_version: "81",
      gene_panel_id: 934,
      genes_count: 17,
      patients_count: 4,
      title: "PanelApp - Cerebellar_hypoplasia",
      total_patients_count: 4,
      updated: 1454432576,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: GPM.cerebellar_hypoplasia.txt",
      ensembl_version: "108",
      gene_panel_id: 935,
      genes_count: 53,
      patients_count: 5,
      title: "GPM.cerebellar_hypoplasia",
      total_patients_count: 5,
      updated: 1454433562,
    },
    {
      archived: 0,
      base_panel_title: null,
      description:
        "Top exomiser genes given sample vcf and disease-id 277470 (Pontocerebellar hypoplasia)",
      ensembl_version: "108",
      gene_panel_id: 950,
      genes_count: 230,
      patients_count: 2,
      title: "PCH_exomiser_OMIM_top_genes: Demo",
      total_patients_count: 2,
      updated: 1454515046,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: Epileptic_encephalopathy.txt",
      ensembl_version: "108",
      gene_panel_id: 951,
      genes_count: 127,
      patients_count: 6,
      title: "PanelApp Epileptic_encephalopathy",
      total_patients_count: 6,
      updated: 1454519440,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: DDG2P_27Apr15.txt",
      ensembl_version: "108",
      gene_panel_id: 953,
      genes_count: 1440,
      patients_count: 3,
      title: "DDG2P_27Apr15",
      total_patients_count: 3,
      updated: 1454522742,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: null,
      ensembl_version: "108",
      gene_panel_id: 963,
      genes_count: 41,
      patients_count: 1,
      title: "PCD: Demo",
      total_patients_count: 1,
      updated: 1412178620,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: "File import: Paediatric Opthalmic.txt",
      ensembl_version: "108",
      gene_panel_id: 2710,
      genes_count: 199,
      patients_count: 1,
      title: "Imported gene panel - Paediatric Opthalmic.txt: Demo",
      total_patients_count: 1,
      updated: 1451990607,
    },
    {
      archived: 0,
      base_panel_title: null,
      description: null,
      ensembl_version: "108",
      gene_panel_id: 2810,
      genes_count: 1,
      patients_count: 0,
      title: "Mitochondrial genes",
      total_patients_count: 0,
      updated: 1467110125,
    },
  ],
};
