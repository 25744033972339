export const response = {
  data: [
    {
      type: "project_patient_metadata_field",
      attributes: {
        category_name: null,
        position: 1,
        project_code: "OncologyProject",
        source_project_id: 7,
        inherited: true,
        field_type: null,
        required: true,
        project_id: 942,
        source_project_code: "Demo",
        created: 1637072271,
        category_id: null,
        updated: 1637072271,
        field_code: "allocated_to",
        visible_in_list: true,
        field_name: "Allocated to",
      },
      id: 304,
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/projects/942/patient_metadata_fields?filter%5Bvisible_in_list%5D=true&page%5Bsize%5D=0",
  },
  meta: {
    count: 1,
  },
  jsonapi: {
    version: "1.0",
  },
};
