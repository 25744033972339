import { response as oncologyPatientMetadata } from "./oncology-patient_metadata_fields";
import { response as rareDiseasePatientMetadata } from "./rd-patient_metadata_fields";

import { PROJECT_ID_ONCOLOGY, PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

const projectIdMap = {
  [PROJECT_ID_ONCOLOGY]: oncologyPatientMetadata,
  [PROJECT_ID_RARE_DISEASE]: rareDiseasePatientMetadata,
};

export default projectIdMap;
