const mockPatient525 = {
  data: {
    type: "patient",
    attributes: {
      annotation_sources: [
        {
          value: "81",
          attribute: "ENSEMBL",
          type: "data",
        },
        {
          value: "81",
          attribute: "VEP_CACHE",
          type: "data",
        },
        {
          value: "81.1",
          attribute: "VEP",
          type: "software",
        },
        {
          value: "some value",
          attribute: "EXOMISER_ACMG_ANNOTATION",
          type: "data",
        },
      ],
      has_svs: true,
      has_snvs: true,
      project_id: 7,
      has_snv_vcf: false,
      project_features_enabled: [
        "splice_ai",
        "revel",
        "gnomad_exomes",
        "snv_annotation_file",
        "exac_exomes",
        "ariadne",
        "API_V3",
        "Oncology",
      ],
      status: "Primary review",
      phenotype_names: [
        "Cerebellar hypoplasia",
        "Olivopontocerebellar hypoplasia",
        "Extramedullary hematopoiesis",
        "Abnormality of the pons",
        "Abnormality of bone marrow cell morphology",
        "Increased adipose tissue around the neck",
        "Abnormality of glossopharyngeal nerve",
        "Chorioretinal scar",
      ],
      reference: "Demo_Child",
      phenotype_summary: null,
      updated: 1635326369,
      has_bam: false,
      automated_decision_time: null,
      created: 1434122084,
      has_log2ratios: false,
      has_coverage: false,
      patient_relatives: [
        {
          patient: {
            patient_id: 526,
            has_svs: true,
            has_snvs: true,
            has_log2ratios: false,
            has_coverage: false,
            reference: "Demo_Father",
            has_snv_vcf: false,
            has_bam: false,
          },
          relation: "Father",
        },
        {
          patient: {
            patient_id: 527,
            has_svs: true,
            has_snvs: true,
            has_log2ratios: false,
            has_coverage: false,
            reference: "Demo_Mother",
            has_snv_vcf: false,
            has_bam: false,
          },
          relation: "Mother",
        },
      ],
      is_proband: true,
      curated_variant_lists: [
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.DECIPHER",
          description:
            "Decipher SNVs and indels (open-access patients) in the 2019-July version of the Decipher dataset",
          curatedVariantListId: 380,
          name: "Decipher SNV 2019-07",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.DECIPHER",
          description:
            "Decipher SNVs and indels (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Pathogenic, Likely pathogenic variants",
          curatedVariantListId: 381,
          name: "Decipher SNV 2019-07 Pathogenic, Likely pathogenic variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.DECIPHER",
          description:
            "Decipher SNVs and indels (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Uncertain significance variants",
          curatedVariantListId: 382,
          name: "Decipher SNV 2019-07 Uncertain significance variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.DECIPHER",
          description:
            "Decipher SNVs and indels (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Benign, Likely benign variants",
          curatedVariantListId: 383,
          name: "Decipher SNV 2019-07 Benign, Likely benign variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.DECIPHER",
          description:
            "Decipher SNVs and indels (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Unknown pathogenicity variants",
          curatedVariantListId: 384,
          name: "Decipher SNV 2019-07 Unknown pathogenicity variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SV.CuratedList.DecipherCNV",
          description:
            "Decipher CNVs (open-access patients) in the 2019-July version of the Decipher dataset",
          curatedVariantListId: 385,
          name: "Decipher CNV 2019-07",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SV.CuratedList.DecipherCNV",
          description:
            "Decipher CNVs (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Pathogenic, Likely pathogenic variants",
          curatedVariantListId: 386,
          name: "Decipher CNV 2019-07 Pathogenic, Likely pathogenic variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SV.CuratedList.DecipherCNV",
          description:
            "Decipher CNVs (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Uncertain significance variants",
          curatedVariantListId: 387,
          name: "Decipher CNV 2019-07 Uncertain significance variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SV.CuratedList.DecipherCNV",
          description:
            "Decipher CNVs (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Benign, Likely benign variants",
          curatedVariantListId: 388,
          name: "Decipher CNV 2019-07 Benign, Likely benign variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SV.CuratedList.DecipherCNV",
          description:
            "Decipher CNVs (open-access patients) in the 2019-July version of the Decipher dataset. Limited to variants with pathogenicity: Unknown pathogenicity variants",
          curatedVariantListId: 389,
          name: "Decipher CNV 2019-07 Unknown pathogenicity variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.Mastermind",
          description:
            "Mastermind SNV and indel variants included in Mastermind CVR-2 version 2020-April and selected according to the rules: MMCNT1>0, MMCNT2>0, MMCNT3>=0, MMCNT2==MMCNT3",
          curatedVariantListId: 421,
          name: "Mastermind CVR-2 SNV 2020-04",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.Clinvar",
          description:
            "ClinVar variants (SNVs and indels) in the 2020-May version of the ClinVar dataset",
          curatedVariantListId: 422,
          name: "ClinVar SNV 2020-05",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.Clinvar",
          description:
            "ClinVar variants (SNVs and indels) in the 2020-May version of the ClinVar dataset. Limited to variants with pathogenicity: Pathogenic, Likely pathogenic variants",
          curatedVariantListId: 423,
          name: "ClinVar SNV 2020-05 Pathogenic, Likely pathogenic variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.Clinvar",
          description:
            "ClinVar variants (SNVs and indels) in the 2020-May version of the ClinVar dataset. Limited to variants with pathogenicity: Uncertain significance variants",
          curatedVariantListId: 424,
          name: "ClinVar SNV 2020-05 Uncertain significance variants",
        },
        {
          genoverseTrackClass: "Genoverse.Track.SNV.CuratedList.Clinvar",
          description:
            "ClinVar variants (SNVs and indels) in the 2020-May version of the ClinVar dataset. Limited to variants with pathogenicity: Benign, Likely benign variants",
          curatedVariantListId: 425,
          name: "ClinVar SNV 2020-05 Benign, Likely benign variants",
        },
        {
          genoverseTrackClass: "",
          description: "automation cvl created for integration testing",
          curatedVariantListId: 427,
          name: "Automated-Classification Demo CVL 2020-06",
        },
      ],
    },
    relationships: {
      gene_panel: {
        links: {
          related: "/webapi/entities/patients/525/automation_gene_panels",
        },
      },
    },
    id: "525",
  },
  jsonapi: {
    version: "1.0",
  },
};

const mockPatient100 = {
  data: {
    type: "patient",
    attributes: {
      annotation_sources: [],
      has_svs: false,
      has_snvs: false,
      project_id: 7,
      has_snv_vcf: false,
      project_features_enabled: [],
      status: "Primary review",
      phenotype_names: [],
      reference: "Empty_Patient",
      phenotype_summary: null,
      updated: 1635326369,
      has_bam: false,
      automated_decision_time: null,
      created: 1434122084,
      has_log2ratios: false,
      has_coverage: false,
      patient_relatives: [],
      is_proband: true,
      curated_variant_lists: [],
    },
    relationships: {},
    id: "100",
  },
  jsonapi: {
    version: "1.0",
  },
};

export default {
  "525": mockPatient525,
  "100": mockPatient100,
};
