const vcfJson = {
  "SNP Alleles": { test: "TEST" },
  "Allele Freq": {},
  Ranges: {
    1000000: { test: "TEST" },
  },
  "Allele Count": { test: "TEST" },
  Heterozygote: { test: "TEST" },
  "Known variant": {},
  "TS-TV": { test: "TEST" },
  Consequence: {},
  "INDEL size": { test: "TEST" },
  Contigs: { test: "TEST" },
  Types: { test: "TEST" },
};

export default vcfJson;
