export const mockReportTempates = {
  recordsFiltered: 2,
  draw: null,
  data: [
    {
      is_inherited: 1,
      report_service_template_id: 50,
      template_uuid: "36a6da92-de4e-11eb-a4ac-612548a3cc7b",
      project_id: 942,
      template_name: "test_oncology",
      updated: 1625571309,
      template_description: "oncology",
    },
    {
      report_service_template_id: 52,
      is_inherited: 0,
      updated: 1625571419,
      template_description: null,
      template_uuid: "77be62d4-de4e-11eb-8f56-612548a3cc7b",
      template_name: "test_oncology_with_type",
      project_id: 942,
    },
  ],
  recordsTotal: 2,
};
