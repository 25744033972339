import { rest } from "msw";

import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";
import geneSearchResponse, {
  geneSearchErrorResponse,
} from "tests/fixtures/catalyst/gene/search/mock-gene-search";
import submitIRSuccessResponse from "tests/fixtures/catalyst/interpretation_request_web/mock-ir-web-response";
import patientGenePanels from "tests/fixtures/catalyst/patient/id/project/gene-panels/mock-patient-gene-panels";
import suggestedACMGCriteria from "tests/fixtures/catalyst/patient/id/variant/id/gene/id/acmg/criteria/suggestions/mock-suggested-acmg-criteria";
import mockPatientsHash from "tests/fixtures/catalyst/patient/mock-patient";
import { mockSignificantStatusesChange } from "tests/fixtures/catalyst/patient/mock-significant-statuses-change";
import mockStatusesHash from "tests/fixtures/catalyst/patient/mock-status";
import mockSnvResponse from "tests/fixtures/catalyst/patient/snv/mock-snv-search";
import { patientsMetadata } from "tests/fixtures/catalyst/project/id/metadata/mock-project-metadata";
import projectCurrentUser from "tests/fixtures/catalyst/project/mock-project-current-user";
import projectPatientsHash from "tests/fixtures/catalyst/project/patients";
import projectsHash from "tests/fixtures/catalyst/project/project";
import mockProjectUsers from "tests/fixtures/catalyst/project/users/mock-project-users";
import svFilters from "tests/fixtures/catalyst/project/variants/sv/filters";
import hpoTerms from "tests/fixtures/catalyst/projects/id/hpo/terms-and-modifiers/mock-terms-and-modifiers";
import { mockJurisdictions } from "tests/fixtures/catalyst/projects/mock-jurisdictions";
import { mockReportTempates } from "tests/fixtures/catalyst/projects/mock-report-templates";

const catalyst = (path: string) => `/catalyst/api${path}`;

export default [
  rest.get(catalyst("/patient/:patientId"), (req, res, ctx) => {
    const { patientId } = req.params;
    const patient = mockPatientsHash[patientId as string];
    if (!patient) return res(ctx.status(400));
    return res(ctx.json(patient));
  }),
  rest.get(catalyst("/patient/:patientId/status"), (req, res, ctx) => {
    const { patientId } = req.params;
    const patientStatus = mockStatusesHash[patientId as string];
    if (!patientStatus) return res(ctx.status(400));
    return res(ctx.json(patientStatus));
  }),
  rest.get(
    catalyst("/patient/:patientId/significant_status_change"),
    (req, res, ctx) => res(ctx.json(mockSignificantStatusesChange))
  ),
  rest.get(
    catalyst("/project/:projectId/report-templates/available"),
    (req, res, ctx) => res(ctx.json(mockReportTempates))
  ),
  rest.get(
    catalyst("/project/:projectId/oncology/jurisdictions"),
    (req, res, ctx) => res(ctx.json(mockJurisdictions))
  ),
  rest.put(catalyst("/patient/:patientId/status"), (req, res, ctx) =>
    res(ctx.status(200))
  ),
  rest.get(
    catalyst("/patient/:patientId/report/patient-data/oncology"),
    (req, res, ctx) => res(ctx.status(200))
  ),
  rest.get(
    catalyst("/project/:projectId/variants/sv/filters"),
    (req, res, ctx) => res(ctx.json(svFilters))
  ),

  rest.get(catalyst("/project/:projectId"), (req, res, ctx) => {
    const projectId = Number(req.params.projectId);
    return res(
      ctx.json(
        projectsHash[Number(projectId)] || projectsHash[PROJECT_ID_RARE_DISEASE]
      )
    );
  }),

  rest.get(catalyst("/project/:projectId/patients"), (req, res, ctx) => {
    const projectId = Number(req.params.projectId);
    return res(
      ctx.json(
        projectPatientsHash[projectId] ||
          projectPatientsHash[PROJECT_ID_RARE_DISEASE]
      )
    );
  }),

  rest.get(catalyst("/project/:projectId/current_user"), (req, res, ctx) =>
    res(ctx.json(projectCurrentUser))
  ),

  rest.get(catalyst("/project/:projectId/users"), (req, res, ctx) =>
    res(ctx.json(mockProjectUsers))
  ),

  rest.get(catalyst("/patient/:patientId/snv"), (req, res, ctx) =>
    res(ctx.json(mockSnvResponse))
  ),

  rest.get(
    catalyst(
      "/patient/:patientId/variant/:variantId/gene/:geneId/acmg/criteria/suggestions"
    ),
    (req, res, ctx) => res(ctx.json(suggestedACMGCriteria))
  ),

  rest.get(catalyst("/project/:projectId/metadata"), (req, res, ctx) =>
    res(ctx.json(patientsMetadata))
  ),

  rest.get(
    catalyst("/project/:projectId/hpo/terms-and-modifiers"),
    (req, res, ctx) => res(ctx.json(hpoTerms))
  ),

  rest.post(catalyst("/interpretation_request_web"), (req, res, ctx) =>
    res(ctx.json(submitIRSuccessResponse))
  ),

  rest.get(
    catalyst("/patient/:patientId/project/gene-panels"),
    (req, res, ctx) => res(ctx.json(patientGenePanels))
  ),

  rest.get(catalyst("/gene/search"), (req, res, ctx) => {
    const search = req.url.searchParams.get("search");
    if (!search) return res(ctx.status(400), ctx.json(geneSearchErrorResponse));

    const filteredGenes = geneSearchResponse.genes.filter(gene =>
      gene.name.toLowerCase().includes(search.toLowerCase())
    );
    return res(
      ctx.json({
        ...geneSearchResponse,
        genes: filteredGenes,
      })
    );
  }),
];
