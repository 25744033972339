const cvlDetailsResponse = {
  data: {
    type: "curated_variant_list",
    attributes: {
      created: 1453901076,
      variant_type: "SNV",
      user_count: 1,
      is_reference: true,
      origin_project_id: "7",
      curators: [],
      show_pathogenicity: true,
      owner_name: "System User",
      filter_presets: [],
      updated: 1593101747,
      owner_email: "system-user@sapientia.co.uk",
      curated_data_schema: {
        type: "object",
        properties: {
          G5: {
            type: "boolean",
            title: ">5% minor allele frequency in 1+ populations",
            propertyOrder: 0,
          },
          HD: {
            type: "boolean",
            title:
              "Marker is on high density genotyping kit (50K density or greater).  The variant may have phenotype associations present in dbGaP.",
            propertyOrder: 320,
          },
          OM: { type: "boolean", title: "Has OMIM/OMIA", propertyOrder: 430 },
          PM: {
            type: "boolean",
            title: "Variant is Precious(Clinical,Pubmed Cited)",
            propertyOrder: 510,
          },
          R3: {
            type: "boolean",
            title: "In 3' gene region FxnCode = 13",
            propertyOrder: 540,
          },
          R5: {
            type: "boolean",
            title: "In 5' gene region FxnCode = 15",
            propertyOrder: 40,
          },
          RS: {
            type: "integer",
            title: "dbSNP ID (i.e. rs number)",
            propertyOrder: 110,
          },
          RV: {
            type: "boolean",
            title: "RS orientation is reversed",
            propertyOrder: 490,
          },
          U3: {
            type: "boolean",
            title:
              "In 3' UTR Location is in an untranslated region (UTR). FxnCode = 53",
            propertyOrder: 10,
          },
          U5: {
            type: "boolean",
            title:
              "In 5' UTR Location is in an untranslated region (UTR). FxnCode = 55",
            propertyOrder: 150,
          },
          VC: { type: "string", title: "Variation Class", propertyOrder: 370 },
          VP: {
            type: "string",
            title:
              "Variation Property.  Documentation is at ftp://ftp.ncbi.nlm.nih.gov/snp/specs/dbSNP_BitField_latest.pdf",
            propertyOrder: 90,
          },
          ASP: {
            type: "boolean",
            title:
              "Is Assembly specific. This is set if the variant only maps to one assembly",
            propertyOrder: 270,
          },
          ASS: {
            type: "boolean",
            title: "In acceptor splice site FxnCode = 73",
            propertyOrder: 20,
          },
          CAF: {
            type: "array",
            items: { type: "string" },
            title:
              "An ordered, comma delimited list of allele frequencies based on 1000Genomes, starting with the reference allele followed by alternate alleles as ordered in the ALT column. Where a 1000Genomes alternate allele is not in the dbSNPs alternate allele set, the allele is added to the ALT column.  The minor allele is the second largest value in the list, and was previuosly reported in VCF as the GMAF.  This is the GMAF reported on the RefSNP and EntrezSNP pages and VariationReporter",
            propertyOrder: 480,
          },
          CDA: {
            type: "boolean",
            title: "Variation is interrogated in a clinical diagnostic assay",
            propertyOrder: 360,
          },
          CFL: {
            type: "boolean",
            title:
              "Has Assembly conflict. This is for weight 1 and 2 variant that maps to different chromosomes on different assemblies.",
            propertyOrder: 220,
          },
          DSS: {
            type: "boolean",
            title: "In donor splice-site FxnCode = 75",
            propertyOrder: 290,
          },
          G5A: {
            type: "boolean",
            title: ">5% minor allele frequency in each and all populations",
            propertyOrder: 570,
          },
          GNO: {
            type: "boolean",
            title:
              "Genotypes available. The variant has individual genotype (in SubInd table).",
            propertyOrder: 390,
          },
          INT: {
            type: "boolean",
            title: "In Intron FxnCode = 6",
            propertyOrder: 340,
          },
          LSD: {
            type: "boolean",
            title: "Submitted from a locus-specific database",
            propertyOrder: 330,
          },
          MTP: {
            type: "boolean",
            title: "Microattribution/third-party annotation(TPA:GWAS,PAGE)",
            propertyOrder: 470,
          },
          MUT: {
            type: "boolean",
            title:
              "Is mutation (journal citation, explicit fact): a low frequency variation that is cited in journal and other reputable sources",
            propertyOrder: 260,
          },
          NOC: {
            type: "boolean",
            title:
              "Contig allele not present in variant allele list. The reference sequence allele at the mapped position is not present in the variant allele list, adjusted for orientation.",
            propertyOrder: 250,
          },
          NOV: {
            type: "boolean",
            title:
              "Rs cluster has non-overlapping allele sets. True when rs set has more than 2 alleles from different submissions and these sets share no alleles in common.",
            propertyOrder: 380,
          },
          NSF: {
            type: "boolean",
            title:
              "Has non-synonymous frameshift A coding region variation where one allele in the set changes all downstream amino acids. FxnClass = 44",
            propertyOrder: 530,
          },
          NSM: {
            type: "boolean",
            title:
              "Has non-synonymous missense A coding region variation where one allele in the set changes protein peptide. FxnClass = 42",
            propertyOrder: 310,
          },
          NSN: {
            type: "boolean",
            title:
              "Has non-synonymous nonsense A coding region variation where one allele in the set changes to STOP codon (TER). FxnClass = 41",
            propertyOrder: 180,
          },
          OTH: {
            type: "boolean",
            title:
              "Has other variant with exactly the same set of mapped positions on NCBI refernce assembly.",
            propertyOrder: 400,
          },
          PMC: {
            type: "boolean",
            title: "Links exist to PubMed Central article",
            propertyOrder: 130,
          },
          REF: {
            type: "boolean",
            title:
              "Has reference A coding region variation where one allele in the set is identical to the reference sequence. FxnCode = 8",
            propertyOrder: 50,
          },
          S3D: {
            type: "boolean",
            title: "Has 3D structure - SNP3D table",
            propertyOrder: 410,
          },
          SAO: {
            type: "integer",
            title:
              "Variant Allele Origin: 0 - unspecified, 1 - Germline, 2 - Somatic, 3 - Both",
            propertyOrder: 230,
          },
          SLO: {
            type: "boolean",
            title: "Has SubmitterLinkOut - From SNP->SubSNP->Batch.link_out",
            propertyOrder: 460,
          },
          SSR: {
            type: "integer",
            title:
              "Variant Suspect Reason Codes (may be more than one value added together) 0 - unspecified, 1 - Paralog, 2 - byEST, 4 - oldAlign, 8 - Para_EST, 16 - 1kg_failed, 1024 - other",
            propertyOrder: 300,
          },
          SYN: {
            type: "boolean",
            title:
              "Has synonymous A coding region variation where one allele in the set does not change the encoded amino acid. FxnCode = 3",
            propertyOrder: 240,
          },
          TPA: {
            type: "boolean",
            title:
              "Provisional Third Party Annotation(TPA) (currently rs from PHARMGKB who will give phenotype data)",
            propertyOrder: 350,
          },
          VLD: {
            type: "boolean",
            title:
              "Is Validated.  This bit is set if the variant has 2+ minor allele count based on frequency or genotype data.",
            propertyOrder: 30,
          },
          WGT: {
            type: "integer",
            title:
              "Weight, 00 - unmapped, 1 - weight 1, 2 - weight 2, 3 - weight 3 or more",
            propertyOrder: 520,
          },
          WTD: {
            type: "boolean",
            title:
              "Is Withdrawn by submitter If one member ss is withdrawn by submitter, then this bit is set.  If all member ss' are withdrawn, then the rs is deleted to SNPHistory",
            propertyOrder: 80,
          },
          RSPOS: {
            type: "integer",
            title: "Chr position reported in dbSNP",
            propertyOrder: 60,
          },
          CLNACC: {
            type: "array",
            items: { type: "string" },
            title: "Variant Accession and Versions",
            propertyOrder: 500,
          },
          CLNDBN: {
            type: "array",
            items: { type: "string" },
            title: "Variant disease name",
            propertyOrder: 210,
          },
          CLNSIG: {
            type: "array",
            items: { type: "string" },
            title:
              "Variant Clinical Significance, 0 - Uncertain significance, 1 - not provided, 2 - Benign, 3 - Likely benign, 4 - Likely pathogenic, 5 - Pathogenic, 6 - drug response, 7 - histocompatibility, 255 - other",
            propertyOrder: 280,
          },
          CLNSRC: {
            type: "array",
            items: { type: "string" },
            title: "Variant Clinical Chanels",
            propertyOrder: 550,
          },
          COMMON: {
            type: "integer",
            title:
              "RS is a common SNP.  A common SNP is one that has at least one 1000Genomes population with a minor allele of frequency >= 1% and for which 2 or more founders contribute to that minor allele frequency.",
            propertyOrder: 440,
          },
          CLNALLE: {
            type: "array",
            items: { type: "integer" },
            title:
              "Variant alleles from REF or ALT columns.  0 is REF, 1 is the first ALT allele, etc.  This is used to match alleles with other corresponding clinical (CLN) INFO tags.  A value of -1 indicates that no allele was found to match a corresponding HGVS allele name.",
            propertyOrder: 100,
          },
          CLNDSDB: {
            type: "array",
            items: { type: "string" },
            title: "Variant disease database name",
            propertyOrder: 170,
          },
          CLNHGVS: {
            type: "array",
            items: { type: "string" },
            title:
              "Variant names from HGVS.    The order of these variants corresponds to the order of the info in the other clinical  INFO tags.",
            propertyOrder: 450,
          },
          CLNSRCID: {
            type: "array",
            items: { type: "string" },
            title: "Variant Clinical Channel IDs",
            propertyOrder: 160,
          },
          GENEINFO: {
            type: "string",
            title:
              "Pairs each of gene symbol:gene id.  The gene symbol and id are delimited by a colon (:) and each pair is delimited by a vertical bar (|)",
            propertyOrder: 190,
          },
          KGPhase1: {
            type: "boolean",
            title: "1000 Genome phase 1 (incl. June Interim phase 1)",
            propertyOrder: 420,
          },
          KGPhase3: {
            type: "boolean",
            title: "1000 Genome phase 3",
            propertyOrder: 70,
          },
          CLNDSDBID: {
            type: "array",
            items: { type: "string" },
            title: "Variant disease database ID",
            propertyOrder: 120,
          },
          CLNORIGIN: {
            type: "array",
            items: { type: "string" },
            title:
              "Allele Origin. One or more of the following values may be added: 0 - unknown; 1 - germline; 2 - somatic; 4 - inherited; 8 - paternal; 16 - maternal; 32 - de-novo; 64 - biparental; 128 - uniparental; 256 - not-tested; 512 - tested-inconclusive; 1073741824 - other",
            propertyOrder: 560,
          },
          CLNREVSTAT: {
            type: "array",
            items: { type: "string" },
            title:
              "no_assertion - No assertion provided, no_criteria - No assertion criteria provided, single - Criteria provided single submitter, mult - Criteria provided multiple submitters no conflicts, conf - Criteria provided conflicting interpretations, exp - Reviewed by expert panel, guideline - Practice guideline",
            propertyOrder: 140,
          },
          dbSNPBuildID: {
            type: "integer",
            title: "First dbSNP Build for RS",
            propertyOrder: 200,
          },
        },
      },
      type: "Black",
      name: "ClinVar",
      is_curator: false,
      is_automated_annotation_decisions: true,
      is_eligible_for_automated_annotation_decisions: false,
      validation_status: "Success",
      description: null,
      variant_count: 5732,
      project_count: 4,
    },
    id: "23",
  },
  jsonapi: { version: "1.0" },
};

export default cvlDetailsResponse;
