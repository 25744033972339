import { ResponseComposition, rest, RestContext, RestRequest } from "msw";

import {
  PATIENT_ID_ONCOLOGY_WITHOUT_QC_DATA,
  PROJECT_ID_RARE_DISEASE,
  PROJECT_ID_RARE_DISEASE_MINIMAL,
} from "tests/fixtures";
import mockAuthenticationProviders from "tests/fixtures/webapi/entities/authentication-providers/mock-authentication-providers";
import mockAuthenticationProvidersByUser from "tests/fixtures/webapi/entities/authentication-providers/users/mock-authentication-providers-by-user";
import mockCurrentUser from "tests/fixtures/webapi/entities/current_user/mock-current-user";
import oncologyQCMetrics from "tests/fixtures/webapi/entities/patient/mock-oncology-qc-metrics";
import { mockPatientReports } from "tests/fixtures/webapi/entities/patient/mock-patient-reports";
import { mockReportProposalLatest } from "tests/fixtures/webapi/entities/patient/mock-report-proposal-latest";
import mockOncologyCopyNumberVariants from "tests/fixtures/webapi/entities/patients/id/oncology/variants/mock-oncology-copy-number-variants";
import mockOncologySequenceVariants from "tests/fixtures/webapi/entities/patients/id/oncology/variants/mock-oncology-sequence-variants";
import mockPatientsHash from "tests/fixtures/webapi/entities/patients/patients";
import cvlOptions from "tests/fixtures/webapi/entities/projects/curated_variant_list_options/mock-cvl-options";
import cvlAuditResponse from "tests/fixtures/webapi/entities/projects/curated_variant_lists/id/audit/mock-cvl-audit";
import cvlDetailsResponse from "tests/fixtures/webapi/entities/projects/curated_variant_lists/id/mock-cvl-details";
import cvlVariantsResponse from "tests/fixtures/webapi/entities/projects/curated_variant_lists/id/variants/mock-cvl-variants";
import curatedVariantListsResponse, {
  singleCuratedVariantListResponse,
} from "tests/fixtures/webapi/entities/projects/curated_variant_lists/mock-curated-variant-lists";
import { featuresSettingsPayload } from "tests/fixtures/webapi/entities/projects/features/mock-features-settings";
import protocols from "tests/fixtures/webapi/entities/projects/id/protocols/mock-protocols";
import projectPatientMetadataFields from "tests/fixtures/webapi/entities/projects/patient_metadata_fields";
import { reportServiceTemplates } from "tests/fixtures/webapi/entities/projects/report_service_templates/report_service_templates";
import mockRoles from "tests/fixtures/webapi/entities/projects/roles/mock-roles";
import mockDefaults from "tests/fixtures/webapi/entities/projects/settings/defaults.json";
import existingListsAvailabilityResponse from "tests/fixtures/webapi/entities/projects/unrelated_curated_variant_lists/exist/mock-unrelated-cvls-exist";
import existingListsResponse from "tests/fixtures/webapi/entities/projects/unrelated_curated_variant_lists/mock-unrelated-cvls";
import { defaultPresets } from "tests/fixtures/webapi/entities/projects/variant-filter-presets/variant-filter-presets";
import thirdPartyData from "tests/fixtures/webapi/entities/thirdparty_data_versions";
import thirdPartySoftware from "tests/fixtures/webapi/entities/thirdparty_software_versions";
import validSampleTypes from "tests/fixtures/webapi/entities/valid_sample_types/mock-valid-sample-types";
import { mockVepConsequences } from "tests/fixtures/webapi/entities/vep_consequences/mock-vep-consequences";
import { defaultSystemConfig } from "tests/fixtures/webapi/utils/config/mock-config";

const webapi = (path: string) => `/webapi${path}`;

export const forceContentTypeHeader = (
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
  json: unknown
) => {
  if (req.headers.get("Content-Type") !== "application/vnd.api+json")
    return res(
      ctx.status(415),
      ctx.json({
        errors: [
          {
            detail: "Content-Type header must be application/vnd.api+json",
            title: "Invalid request header",
          },
        ],
      })
    );

  return res(ctx.json(json));
};

export default [
  rest.get(webapi("/entities/current_user"), (req, res, ctx) =>
    res(ctx.json(mockCurrentUser))
  ),

  rest.get(
    webapi("/entities/projects/:projectId/settings/defaults"),
    (req, res, ctx) => res(ctx.json(mockDefaults))
  ),

  rest.get(webapi("/entities/patients/:patientId"), (req, res, ctx) => {
    const { patientId } = req.params;
    const patient = mockPatientsHash[patientId as string];
    if (!patient) return res(ctx.status(404));
    return res(ctx.json(patient));
  }),

  rest.get(webapi("/entities/thirdparty_data_versions"), (req, res, ctx) => {
    const isActiveFilter = req.url.searchParams.get("filter[is_active]");
    const nameFilter = req.url.searchParams.get("filter[name]");
    const filteredData = thirdPartyData.data
      .filter(version =>
        isActiveFilter
          ? String(version.attributes.is_active) === isActiveFilter
          : true
      )
      .filter(version =>
        nameFilter ? version.attributes.name === nameFilter : true
      );
    return res(
      ctx.json({
        ...thirdPartyData,
        data: filteredData,
      })
    );
  }),

  rest.get(
    webapi("/entities/thirdparty_software_versions"),
    (req, res, ctx) => {
      const isActiveFilter = req.url.searchParams.get("filter[is_active]");
      const nameFilter = req.url.searchParams.get("filter[name]");
      const filteredData = thirdPartySoftware.data
        .filter(version =>
          isActiveFilter
            ? String(version.attributes.is_active) === isActiveFilter
            : true
        )
        .filter(version =>
          nameFilter ? version.attributes.name === nameFilter : true
        );
      return res(
        ctx.json({
          ...thirdPartySoftware,
          data: filteredData,
        })
      );
    }
  ),

  rest.get(webapi("/entities/patients/:patientId/reports"), (req, res, ctx) =>
    res(ctx.json(mockPatientReports))
  ),
  rest.get(
    webapi("/entities/patients/:patientId/report_proposal/latest"),
    (req, res, ctx) => {
      const { patientId } = req.params;
      if (patientId === "10918") return res(ctx.json({}));
      return res(ctx.json(mockReportProposalLatest));
    }
  ),
  rest.get(
    webapi("/entities/patients/:patientId/oncology/qc_metrics/download"),
    (req, res, ctx) => res(ctx.status(200))
  ),
  rest.post(
    webapi("/entities/patients/:patientId/report_proposal"),
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.get(
    webapi("/entities/projects/:projectId/variant-filter-presets"),
    (req, res, ctx) => {
      const variantTypeFilter = req.url.searchParams.get(
        "filter[variant_type]"
      );
      const isActiveFilter = req.url.searchParams.get("filter[is_active]");

      const filteredData = defaultPresets.data
        .filter(version =>
          variantTypeFilter
            ? String(version.attributes.variant_type) === variantTypeFilter
            : true
        )
        .filter(version =>
          isActiveFilter
            ? String(version.attributes.is_active) === isActiveFilter
            : true
        );
      return res(
        ctx.json({
          ...defaultPresets,
          data: filteredData,
        })
      );
    }
  ),

  rest.get(webapi("/entities/vep_consequences"), (req, res, ctx) =>
    res(ctx.json(mockVepConsequences))
  ),

  rest.get(webapi("/utils/config"), (req, res, ctx) =>
    res(ctx.json(defaultSystemConfig))
  ),

  rest.get(
    webapi("/entities/projects/:projectId/patient_metadata_fields"),
    (req, res, ctx) => {
      const projectId = Number(req.params.projectId);
      return res(
        ctx.json(
          projectPatientMetadataFields[projectId] ||
            projectPatientMetadataFields[PROJECT_ID_RARE_DISEASE]
        )
      );
    }
  ),

  rest.get(
    webapi("/entities/patients/:patientId/oncology/qc_metrics"),
    (req, res, ctx) => {
      const patientId = Number(req.params.patientId);
      if (patientId === PATIENT_ID_ONCOLOGY_WITHOUT_QC_DATA) {
        return res(ctx.status(404));
      }
      return res(ctx.json(oncologyQCMetrics));
    }
  ),

  rest.get(
    webapi(
      "/entities/patients/:patientId/oncology/secondary_analysis_data/download"
    ),
    (req, res, ctx) => res(ctx.status(200))
  ),

  rest.get(webapi("/entities/authentication_providers"), (req, res, ctx) =>
    res(ctx.json(mockAuthenticationProviders))
  ),

  rest.get(
    webapi("/entities/authentication_providers/users/:userId"),
    (req, res, ctx) => res(ctx.json(mockAuthenticationProvidersByUser))
  ),

  rest.get(webapi("/entities/projects/roles"), (req, res, ctx) =>
    res(ctx.json(mockRoles))
  ),

  rest.get(
    webapi("/entities/projects/:projectId/features/settings"),
    (req, res, ctx) => res(ctx.json(featuresSettingsPayload))
  ),

  rest.patch(
    webapi("/entities/projects/:projectId/features/settings"),
    (req, res, ctx) =>
      forceContentTypeHeader(req, res, ctx, featuresSettingsPayload)
  ),

  rest.get(
    webapi("/entities/projects/:projectId/report_service_templates"),
    (req, res, ctx) => res(ctx.json(reportServiceTemplates))
  ),

  /**
   * List of available CVLs
   */
  rest.get(
    webapi("/entities/projects/:projectId/curated_variant_lists"),
    (req, res, ctx) => {
      const { projectId } = req.params;
      if (Number(projectId) === PROJECT_ID_RARE_DISEASE_MINIMAL)
        return res(ctx.json(singleCuratedVariantListResponse));
      return res(ctx.json(curatedVariantListsResponse));
    }
  ),

  /**
   * Individual CVL details
   */
  rest.get(
    webapi("/entities/projects/:projectId/curated_variant_lists/:cvlId"),
    (req, res, ctx) => res(ctx.json(cvlDetailsResponse))
  ),
  rest.get(
    webapi(
      "/entities/projects/:projectId/curated_variant_lists/:cvlId/variants"
    ),
    (req, res, ctx) => res(ctx.json(cvlVariantsResponse))
  ),
  rest.get(
    webapi("/entities/projects/:projectId/curated_variant_lists/:cvlId/audit"),
    (req, res, ctx) => res(ctx.json(cvlAuditResponse))
  ),

  /**
   * Unrelated CVLs
   */
  rest.get(
    webapi("/entities/projects/:projectId/unrelated_curated_variant_lists"),
    (req, res, ctx) => res(ctx.json(existingListsResponse))
  ),
  rest.get(
    webapi(
      "/entities/projects/:projectId/unrelated_curated_variant_lists/exist"
    ),
    (req, res, ctx) => res(ctx.json(existingListsAvailabilityResponse))
  ),

  /**
   * CVL Options
   */
  rest.get(
    webapi("/entities/projects/:projectId/curated_variant_list_options"),
    (req, res, ctx) => res(ctx.json(cvlOptions))
  ),

  /**
   * SNV variant data that will contain all the values needed by the Explore tab.
   */
  rest.get(
    webapi("/entities/patients/:patientId/oncology/variants/snvs"),
    (req, res, ctx) => res(ctx.json(mockOncologySequenceVariants))
  ),

  /**
   * Valid sample types
   */
  rest.get(webapi("/entities/valid_sample_types"), (req, res, ctx) =>
    res(ctx.json(validSampleTypes))
  ),

  /**
   * Protocols
   */
  rest.get(webapi("/entities/projects/:projectId/protocols"), (req, res, ctx) =>
    res(ctx.json(protocols))
  ),

  /**
   * CNV variant data that will contain all the values needed by the Explore tab.
   */
  rest.get(
    webapi("/entities/patients/:patientId/oncology/variants/cnvs"),
    (req, res, ctx) => res(ctx.json(mockOncologyCopyNumberVariants))
  ),
];
