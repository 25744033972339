import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

const submitIRSuccessResponse = {
  project_id: PROJECT_ID_RARE_DISEASE,
  friendly_status: "READY_TO_PROCESS",
  project_code: "Demo",
  reference: "test",
  interpretation_request_id: 790,
  upload_source: "web",
};

export default submitIRSuccessResponse;
