import {
  ACTION_COMPLETE_REVIEW,
  ACTION_PASS_QC,
  ACTION_UNDO_QC,
  QC_CHECKED,
  READY_FOR_REVIEW,
  REVIEW_COMPLETE,
} from "modules/oncologyReport/constants";

export const mockSignificantStatusesChange = {
  [REVIEW_COMPLETE]: {
    email: "superadmin@test.com",
    fullname: "superadmin",
    user_id: 426,
    action: ACTION_COMPLETE_REVIEW,
    updated: 1642424284,
  },
  [QC_CHECKED]: {
    updated: 1643118851,
    action: ACTION_PASS_QC,
    user_id: 426,
    fullname: "superadmin",
    email: "superadmin@test.com",
  },
  [READY_FOR_REVIEW]: {
    fullname: "superadmin",
    email: "superadmin@test.com",
    action: ACTION_UNDO_QC,
    updated: 1643118849,
    user_id: 426,
  },
};
