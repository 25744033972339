const variantHistoryResponse = [
  {
    userId: 425,
    auditType: "pathogenicity",
    decisionId: 101042,
    dateEpoch: 1476916036,
    auditValue: "Likely pathogenic",
    criteria: null,
    userName: "System User",
  },
  {
    userName: "System User",
    criteria: null,
    auditValue: "Partial",
    dateEpoch: 1476916038,
    userId: 425,
    auditType: "acmg_suggestions_used",
    suggestedTranscript: "Transcript123",
    decisionId: 101043,
  },
  {
    userId: 425,
    auditType: "acmg_suggestions_used",
    decisionId: 101044,
    criteria: [
      {
        codeFormatted: "Code 1",
        displayStyle: "danger",
      },
      {
        codeFormatted: "Code 2",
        displayStyle: "success",
      },
    ],
    userName: "System User",
    auditValue: "Primary finding",
    dateEpoch: 1476916045,
  },
  {
    criteria: null,
    userName: "System User",
    auditValue: "Uncertain significance",
    dateEpoch: 1476917604,
    auditType: "variant_article",
    userId: 425,
    decisionId: 101045,
    article: {
      title: "article title",
      abstract: "<p>abstract</p>",
      authors: "Test author",
      pmid: 124,
    },
  },
  {
    decisionId: 101046,
    userId: 425,
    auditType: "variant_article",
    dateEpoch: 1476917607,
    auditValue: "Uncertain",
    criteria: null,
    userName: "System User",
    article: {
      title: "article title",
      abstract: "<p>abstract</p>",
      authors: "Test author",
      url: "http://url.com",
    },
  },
  {
    userId: 425,
    auditType: "variant_article_comment",
    decisionId: 101607,
    auditValue: "wrapped\rtext",
    dateEpoch: 1476985610,
    criteria: null,
    userName: "System User",
    article: {
      title: "article title",
      abstract: "<p>abstract</p>",
      authors: "Test author",
      url: "http://url.com",
    },
  },
  {
    userId: 425,
    auditType: "unknown_type",
    decisionId: 101607,
    auditValue: "wrapped\rtext",
    dateEpoch: 1476986610,
    criteria: null,
    userName: "System User",
    article: {
      title: "article title",
      abstract: "<p>abstract</p>",
      authors: "Test author",
      url: "http://url.com",
    },
  },
  {
    userId: 425,
    auditType: "unknown_type",
    decisionId: 101607,
    auditValue: null,
    dateEpoch: 1476984610,
    criteria: null,
    userName: "System User",
    article: {
      title: "article title",
      abstract: "<p>abstract</p>",
      authors: "Test author",
      url: "http://url.com",
    },
  },
];

export default variantHistoryResponse;
