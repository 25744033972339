import { GENE_NAME_PRESENT } from "tests/fixtures";

const geneSearchResponse = {
  genes: [
    {
      name: GENE_NAME_PRESENT,
      gene_id: 32,
      old_name: "AARSL",
      synonyms: "KIAA1270, bA444E17.1",
    },
  ],
};

export const geneSearchErrorResponse = {
  error: "Parameter error: missing required argument(s): search",
};

export default geneSearchResponse;
