const cvlOptions = {
  data: {
    id: 7,
    attributes: {
      curators: [
        {
          data: {
            id: 3,
            attributes: { fullname: "John Doe" },
          },
        },
        {
          data: {
            id: 2,
            attributes: { fullname: "John Doe2" },
          },
        },
      ],
      list_types: ["Knowledgebase", "Black", "White"],
      variant_types: ["SNV", "CNV"],
    },
  },
};

export default cvlOptions;
