const cvlVariantsResponse = {
  data: [
    {
      type: "curated_variant",
      attributes: {
        gene: {
          data: {
            type: "gene",
            attributes: {
              name: "COMP",
              synonyms: "MED, THBS5",
              morbid_id: "132400, 177170",
              hgnc_id: "2227",
            },
            id: "4622",
          },
        },
        updated: 1676987026,
        name: "NM_000095.2:c.1156A>G",
        added: 1676987026,
        curated_data: {
          disease: "",
          comments: "",
          zygosity: "Heterozygous",
          phenotype_terms: "",
          phenotype_description: "",
          contribution_to_phenotype: "",
        },
        variant: {
          data: {
            type: "variant",
            attributes: {
              alt_allele: "C",
              end: 18897441,
              ref_allele: "T",
              chr: "19",
              start: 18897440,
            },
            id: "1683454",
          },
        },
        updater: {
          data: {
            type: "users",
            relationships: {
              projects: { links: { related: "/webapi/entities/projects" } },
            },
            id: 727,
            attributes: {
              lastlogin: 1680554858,
              email: "marina.navasiolava@artezio.com",
              full_name: "Marina Navasiolava",
              deactivated: null,
              created: 1643920814,
              updated: 1680554858,
              is_admin: true,
            },
          },
        },
        pathogenicity: "Pathogenic",
        curated_variant_list_id: "220",
      },
      id: "5152806",
    },
  ],
  links: {
    self: "http://sapientia-python:5003/webapi/entities/projects/7/curated_variant_lists/220/variants?page%5Bsize%5D=10&page%5Bnumber%5D=1&sort=-updated",
  },
  meta: { count: 1 },
  jsonapi: { version: "1.0" },
};

export default cvlVariantsResponse;
