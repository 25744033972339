export default {
  jsonapi: {
    version: "1.0",
  },
  data: {
    type: "qc_metrics",
    id: "...",
    attributes: {
      amount_dna_ng: 100,
      average_insert_size_bp: "196.4",
      average_read_length_bp: "79",
      avg_coding_depth: 14.1789532749196,
      avg_depth: 16.5894525170303,
      avg_other_depth: "NA",
      avg_snp_depth: "NA",
      baitset_id: "0425761",
      contamination_idx: "Unknown",
      design_size_mb: 0.787714,
      discordant_pct: 0,
      duplicates_pct: 0,
      gender: "male",
      genotype_match_pct: "NA",
      germline_consent: "Yes",
      library_conc_ng_ul: 2,
      library_type: "panel",
      lims_id: "1001",
      match_norm_expec: "No",
      msi_markers: "0/11",
      non_reference_pct: 2.04192061193752,
      normal_lims_id: "UNM0425761",
      normal_sample_id: "UNM-TCRB6N",
      normal_sequencing_container_id: "NORMPANEL",
      on_target_mb: 13.067744,
      on_target_pct: 99.99,
      pipeline: "HOLMES 1.3tmb",
      preservation_type: "Unfixed",
      project: "TestProject",
      retrotransposon_activity: 0,
      sample_id: "11151",
      sample_type: "Tumour",
      sequencer_type: "HiSeq2500",
      sequencing_container_id: "seq1",
      static_files: "1.4pre6",
      st_dev_insert_size_bp: "37.9",
      timestamp: "2021-07-08 08:20:57",
      tmb: 0,
      tube_barcode: "1001",
      tumour_type: "Pancreatic Carcinoma",
      unique_sequence_generated_mb: 13.068504,
      unmapped_pct: 0,
      gene_coverage_qc: [
        {
          coverage_threshold: 400,
          genes: [
            { gene: "TP53", covered_pct: 99.75 },
            { gene: "PTEN", covered_pct: 89.2 },
            { gene: "MYC", covered_pct: 100 },
            { gene: "CCNE1", covered_pct: 97.9 },
            { gene: "KRAS", covered_pct: 99.8 },
            { gene: "ARID1A", covered_pct: 98.0 },
          ],
        },
        {
          coverage_threshold: 300,
          genes: [
            { gene: "TP53", covered_pct: 99.9 },
            { gene: "PTEN", covered_pct: 93.2 },
            { gene: "MYC", covered_pct: 100 },
            { gene: "CCNE1", covered_pct: 98.5 },
            { gene: "KRAS", covered_pct: 100 },
            { gene: "ARID1A", covered_pct: 99.0 },
          ],
        },
        {
          coverage_threshold: 200,
          genes: [
            { gene: "TP53", covered_pct: 100 },
            { gene: "PTEN", covered_pct: 95.9 },
            { gene: "MYC", covered_pct: 100 },
            { gene: "CCNE1", covered_pct: 99.8 },
            { gene: "KRAS", covered_pct: 100 },
            { gene: "ARID1A", covered_pct: 100 },
          ],
        },
        {
          coverage_threshold: 100,
          genes: [
            { gene: "TP53", covered_pct: 99.7 },
            { gene: "PTEN", covered_pct: 89.2 },
            { gene: "MYC", covered_pct: 100 },
            { gene: "CCNE1", covered_pct: 97.9 },
            { gene: "KRAS", covered_pct: 99.8 },
            { gene: "ARID1A", covered_pct: 98.0 },
          ],
        },
        {
          coverage_threshold: 50,
          genes: [
            { gene: "TP53", covered_pct: 100 },
            { gene: "PTEN", covered_pct: 100 },
            { gene: "MYC", covered_pct: 100 },
            { gene: "CCNE1", covered_pct: 100 },
            { gene: "KRAS", covered_pct: 100 },
            { gene: "ARID1A", covered_pct: 100 },
          ],
        },
      ],
    },
  },
};
