export const defaultSystemConfig = {
  sapientia_assembly: "GRCh37",
  sentieon_available: false,
  cloud_provider: "disabled",
  ce_marking: true,
  user_guide: {
    rare_disease: {
      user_guide: "/user-guide/row/Default.htm",
      user_guide_us: "/user-guide/us/Default.htm",
      instructions_for_use: "/user-guide/eu/Default.htm",
    },
    oncology: {
      user_guide: "/user-guide/oncology/row/Default.htm",
      instructions_for_use: "/user-guide/oncology/eu/Default.htm",
    },
  },
  versions: {
    rare_disease: "3.7.0.0+168953",
    oncology: "1.1.0.0",
  },
  oncology_allow_third_party_variant_calling: false,
  sample_files_root_folders: ["/pipeline/source/data"],
};
