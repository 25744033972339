const suggestedACMGCriteria = {
  suggestedACMG: {
    suggestedClassification: null,
    suggestedCriteriaStatus: "pending",
    suggestedCriteria: [
      {
        classificationType: {
          id: 1,
          strengths: [
            { name: "Very Strong", prefix: "VS", id: 1 },
            { id: 2, prefix: "S", name: "Strong" },
            { id: 3, prefix: "M", name: "Moderate" },
            { prefix: "P", name: "Supporting", id: 4 },
          ],
          style: "danger",
          name: "Pathogenic",
        },
        name: "PM2",
        comment:
          "Using strength Supporting because The variant doesn't alter the protein length (missense) and the position is not moderately conserved (phyloP100way = 2.51 is less than 6.8). GnomAD exomes allele count = 0 is less than 5 for AD gene DVL3 (unable to check gnomAD exomes coverage). Variant not found in gnomAD genomes (good gnomAD genomes coverage = 31.2).",
        strength: { prefix: "P", name: "Supporting", id: 4 },
        status: "pending",
        description:
          "Absent from controls (or at extremely low frequency if recessive) in Exome Sequencing Project, 1000 Genomes Project, or Exome Aggregation Consortium",
        category: { id: 1, name: "Population Data" },
      },
      {
        comment:
          "Pathogenic computational verdict based on 9 pathogenic predictions from CADD, DEOGEN2, FATHMM-MKL, LIST-S2, M-CAP, MutationAssessor, MutationTaster, PrimateAI and SIFT vs 3 benign predictions from BayesDel_addAF, MVP and Polyphen2-HVAR (and 1 uncertain prediction from EIGEN).",
        name: "PP3",
        classificationType: {
          id: 1,
          strengths: [
            { name: "Very Strong", prefix: "VS", id: 1 },
            { id: 2, prefix: "S", name: "Strong" },
            { id: 3, prefix: "M", name: "Moderate" },
            { prefix: "P", name: "Supporting", id: 4 },
          ],
          style: "danger",
          name: "Pathogenic",
        },
        category: { id: 2, name: "Computational and predictive data" },
        strength: { prefix: "P", name: "Supporting", id: 4 },
        status: "pending",
        description:
          "Multiple lines of computational evidence support a deleterious effect on the gene or gene product (conservation, evolutionary, splicing impact, etc.)",
      },
    ],
    suggestedTranscript: "NM_004423",
  },
};

export default suggestedACMGCriteria;
