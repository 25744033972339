const mockCurrentUser = {
  data: {
    type: "users",
    attributes: {
      updated: 1677662305,
      created: 1620655543,
      email: "joe.bloggs@congenica.com",
      deactivated: null,
      is_admin: false,
      full_name: "Joe Bloggs",
      lastlogin: 1677662305,
    },
    relationships: {
      projects: {
        links: {
          related: "/webapi/entities/projects",
        },
      },
    },
    id: 123,
  },
  jsonapi: {
    version: "1.0",
  },
};

export default mockCurrentUser;
