import {
  SUCCESS_STATUS,
  LOADING_STATUS,
  FAILED_STATUS,
} from "modules/curatedVariantsLists/constants";

import {
  CVL_ID_NOT_ELIGIBLE_FOR_AUTOMATION,
  CVL_ID_PROJECT_OWN,
  CVL_NAME_PROJECT_OWN,
  CVL_NAME_INHERITED,
  CVL_ID_INHERITED,
  CVL_NAME_NOT_ELIGIBLE_FOR_AUTOMATION,
  CVL_NAME_ELIGIBLE_FOR_AUTOMATION_IN_LOADING_STATE,
  CVL_ID_ELIGIBLE_FOR_AUTOMATION_IN_LOADING_STATE,
} from "tests/fixtures";

const mockAutomationCvl = {
  attributes: {
    owner_name: "projectadmin",
    updated: 1591733763,
    description: null,
    validation_status: SUCCESS_STATUS,
    variant_type: "SNV",
    user_count: 2,
    owner_email: "projectadmin@test.com",
    created: 1591733763,
    is_automated_annotation_decisions: true,
    is_eligible_for_automated_annotation_decisions: true,
    name: CVL_NAME_PROJECT_OWN,
    project_count: 1,
    type: "Knowledgebase",
  },
  id: CVL_ID_PROJECT_OWN,
};

const mockAutomationCvlInLoadingState = {
  attributes: {
    ...mockAutomationCvl.attributes,
    name: CVL_NAME_ELIGIBLE_FOR_AUTOMATION_IN_LOADING_STATE,
    validation_status: LOADING_STATUS,
  },
  id: CVL_ID_ELIGIBLE_FOR_AUTOMATION_IN_LOADING_STATE,
};

export const singleCuratedVariantListResponse = {
  data: [mockAutomationCvl],
  meta: { count: 1 },
};

const curatedVariantListsResponse = {
  data: [
    mockAutomationCvl,
    mockAutomationCvlInLoadingState,
    {
      attributes: {
        owner_name: "projectadmin",
        updated: 1591733763,
        description: "Not eligible for automation",
        validation_status: SUCCESS_STATUS,
        variant_type: "SNV",
        user_count: 2,
        owner_email: "projectadmin@test.com",
        created: 1591733763,
        is_automated_annotation_decisions: true,
        is_eligible_for_automated_annotation_decisions: false,
        name: CVL_NAME_NOT_ELIGIBLE_FOR_AUTOMATION,
        project_count: 1,
        type: "Knowledgebase",
      },
      id: CVL_ID_NOT_ELIGIBLE_FOR_AUTOMATION,
    },
    {
      attributes: {
        owner_name: "projectadmin",
        updated: 1591733763,
        description: "To test filtering",
        validation_status: SUCCESS_STATUS,
        variant_type: "SV",
        user_count: 2,
        owner_email: "projectadmin@test.com",
        created: 1591733763,
        is_automated_annotation_decisions: true,
        is_eligible_for_automated_annotation_decisions: true,
        name: "Auto SV",
        project_count: 1,
        type: "Knowledgebase",
      },
      id: "160",
    },
    {
      attributes: {
        is_reference: false,
        owner_name: "System User",
        updated: 1446212741,
        description: null,
        validation_status: SUCCESS_STATUS,
        variant_count: 27,
        variant_type: "SNV",
        user_count: 1,
        owner_email: "system-user@sapientia.co.uk",
        created: 1446212741,
        parent_project_name: "Demo",
        is_automated_annotation_decisions: false,
        is_eligible_for_automated_annotation_decisions: false,
        name: CVL_NAME_INHERITED,
        project_count: 1,
        type: "Knowledgebase",
      },
      id: CVL_ID_INHERITED,
    },
    {
      attributes: {
        is_reference: true,
        owner_name: "System User",
        updated: 1557136108,
        description: null,
        validation_status: SUCCESS_STATUS,
        variant_count: 5732,
        variant_type: "SNV",
        user_count: 1,
        owner_email: "system-user@sapientia.co.uk",
        created: 1453901076,
        is_automated_annotation_decisions: false,
        is_eligible_for_automated_annotation_decisions: false,
        name: "ClinVarOwn",
        project_count: 2,
        type: "Knowledgebase",
      },
      id: "23",
    },
    {
      attributes: {
        is_reference: true,
        owner_name: "System User",
        updated: 1557136108,
        description: null,
        validation_status: SUCCESS_STATUS,
        variant_count: 5732,
        variant_type: "SNV",
        user_count: 1,
        owner_email: "system-user@sapientia.co.uk",
        created: 1453901076,
        parent_project_name: "Congenica",
        is_automated_annotation_decisions: false,
        is_eligible_for_automated_annotation_decisions: false,

        name: "ClinVar",
        project_count: 2,
        type: "Knowledgebase",
      },
      id: "24",
    },
    {
      attributes: {
        owner_name: "projectadmin",
        updated: 1591733763,
        description: null,
        validation_status: FAILED_STATUS,
        variant_type: "SNV",
        user_count: 2,
        owner_email: "projectadmin@test.com",
        created: 1591733763,
        is_automated_annotation_decisions: false,
        is_eligible_for_automated_annotation_decisions: false,
        name: "Failed cvl",
        project_count: 1,
        type: "Knowledgebase",
      },
      id: "33",
    },
  ],
  meta: { count: 26 },
};

export default curatedVariantListsResponse;
