export const response = {
  keys: [
    "gene_id",
    "name",
    "old_name",
    "synonyms",
    "chr",
    "start",
    "end",
    "description",
    "hi_score",
    "hi_index",
    "hi_rgb",
    "omim_id",
    "morbid_id",
    "ensembl_id",
    "gencode_id",
    "hgnc_id",
    "ucsc_id",
    "refseq_id",
    "uniprot_id",
    "enzyme_id",
    "pubmed_id",
    "pfam_id",
    "interpro_id",
    "orphanet_id",
    "ddgene_code",
    "lrg_id",
    "entrez_id",
    "default_transcript_id",
  ],
  gene: {
    entrez_id: "21",
    pubmed_id: "8706931",
    orphanet_id: null,
    enzyme_id: null,
    ensembl_id: "ENSG00000167972",
    uniprot_id: "Q99758",
    end: 2390747,
    synonyms: "ABC-C, EST111653, LBM180",
    gene_id: 49,
    old_name: "ABC3",
    interpro_id: "IPR003439, IPR003593, IPR027417",
    ucsc_id: "uc002cpy.1",
    name: "ABCA3",
    morbid_id: "610921",
    default_transcript_id: 35556,
    hi_index: 65.8,
    ddgene_code: null,
    start: 2325882,
    hi_rgb: "75,180,0",
    omim_id: [601615],
    pfam_id: "PF00005",
    description: "ATP-binding cassette, sub-family A (ABC1), member 3",
    refseq_id: "NM_001089",
    hgnc_id: "33",
    hi_score: 0.117,
    chr: "16",
    lrg_id: null,
    gencode_id: "ENSG00000167972.8",
  },
  skip_cols: { default_transcript_id: 1, gene_id: 1 },
};
