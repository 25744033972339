export const mockPatientReports = {
  data: [
    {
      type: "patient_report",
      attributes: {
        created: 1589874111,
        updated: 1589874111,
        template_uuid: "36a6da92-de4e-11eb-a4ac-612548a3cc7b",
        user_id: 426,
        variant_count: 0,
      },
      id: "a8a7d500-99a4-11ea-9fca-c0be3427deb7",
      relationships: {
        report_service_template: {
          links: {
            related:
              "/webapi/entities/report_template/7c8dd212-99a4-11ea-8a1d-bdbe3427deb7",
          },
        },
      },
    },
  ],
};
