const validSampleTypes = {
  data: [
    { type: "sample_types", id: "1", attributes: { name: "Blood" } },
    { type: "sample_types", id: "2", attributes: { name: "Saliva" } },
    { type: "sample_types", id: "3", attributes: { name: "CVS" } },
    { type: "sample_types", id: "4", attributes: { name: "Amnio" } },
    { type: "sample_types", id: "5", attributes: { name: "Tissue" } },
    { type: "sample_types", id: "7", attributes: { name: "Other" } },
  ],
};

export default validSampleTypes;
