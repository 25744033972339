import {
  PROJECT_ID_RARE_DISEASE,
  ROOT_SNV_FILTER_PRESET_NAME,
  ROOT_SV_FILTER_PRESET_NAME,
} from "tests/fixtures";

const defaultPresets = {
  data: [
    {
      type: "variant_filter_preset",
      attributes: {
        is_inherited: false,
        title: ROOT_SNV_FILTER_PRESET_NAME,
        config: {
          columns: {
            variantColumns: ["variant", "__CONSEQUENCES_TABLE__"],
            consequenceColumns: ["transcript"],
          },
          filters: {
            moi: [],
            afMax: "",
            genes: [],
            location: "panels",
            zygosity: [],
            customTier: [],
            genePanels: [],
            revelScore: "",
            inheritance: [],
            denovoStatus: [],
            filterExclude: [],
            filterInclude: [],
            vepConsequence: [],
            customPenetrance: [],
            familyComparisons: [],
            curatedListBlacklist: [],
            curatedListWhitelist: [],
            curatedListKnowledgebase: [],
            suggestedAcmgPathogenicity: [],
          },
          prioritisation: [],
        },
        variant_type: "SNV",
        project_id: PROJECT_ID_RARE_DISEASE,
        is_active: true,
        is_default: true,
      },
      id: 1,
    },
    {
      type: "variant_filter_preset",
      attributes: {
        project_id: PROJECT_ID_RARE_DISEASE,
        title: ROOT_SV_FILTER_PRESET_NAME,
        is_inherited: false,
        config: {
          columns: [
            "bands",
            "start",
            "end",
            "length",
            "subtype",
            "genotype",
            "cnv_ratio",
            "quality",
            "bayes_factor",
            "variant_gene",
            "hi_gene_name",
            "hi_index",
            "inheritance",
            "keyword",
            "pathogenicity",
            "translocation_chr",
            "translocation_pos",
            "variant",
            "custom_data",
          ],
          filters: {
            cvl: [],
            size: 0,
            tier: [],
            genes: [],
            quality: 0,
            subtype: [],
            genotype: [],
            location: "panels",
            gene_panel: [
              {
                gene_panel_id: 934,
                gene_panel_title: "PanelApp - Cerebellar_hypoplasia",
              },
              {
                gene_panel_id: 3,
                gene_panel_title: "GPM.cerebellar_hypoplasia",
              },
              {
                gene_panel_id: 2,
                gene_panel_title: "Inherited panel old version",
              },
              {
                gene_panel_id: 1,
                gene_panel_title: "Inherited panel latest version",
              },
              {
                gene_panel_id: "any_gene",
                gene_panel_title: "Any SV associated with a gene",
              },
            ],
            inheritance: [],
          },
        },
        variant_type: "SV",
        is_default: false,
        is_active: true,
      },
      id: 2,
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/projects/7/variant-filter-presets?filter%5Bvariant_type%5D=SV&filter%5Bis_active%5D=true&sort=title",
  },
  meta: { count: 4 },
  jsonapi: { version: "1.0" },
};

export { defaultPresets };
