const mockSnvResponse = {
  list: [
    {
      default_transcript_id: 42200,
      description: "cytochrome P450, family 2, subfamily B, polypeptide 6",
      gene_id: 5297,
      hi_rgb: "75,180,0",
      hi_score: 0.123,
      morbid_id: "614546",
      name: "CYP2B6",
      old_name: "CYP2B",
      omim_id: [123930],
      synonyms: "CPB6, CYPIIB6",
      transcripts: [
        {
          name: "XM_005258570",
          transcript_id: 261335,
        },
      ],
      variants: [
        {
          ada_score: null,
          ariadne_computed_c2p: null,
          ariadne_computed_decision: null,
          ariadne_computed_secondary_finding_c2p: null,
          ariadne_score: null,
          curations: [
            {
              curated_data:
                '{"B": "698181", "C": "123930", "D": "Gln-His", "E": "NULL", "F": "NULL", "G": "172", "H": "172", "I": "Gln172His", "J": "516G>T", "K": "516GtoT | Q172H", "M": "rs3745274", "N": "DFP", "O": "Dobrinas", "P": "Pharmacogenet Genomics", "Q": "Pharmacogenetics and genomics", "R": "23", "S": "84", "T": "2013", "U": "23249875", "V": "PRI", "X": "NULL", "Y": "2013-01-22", "Z": "M", "AD": "CYP2B6", "AE": "19q13.2", "AG": ["Altered (S)-methadone metabolism"], "AH": ["Cytochrome P450, family 2, subfamily B, polypeptide 6"]}',
              curated_variant_id: 3401535,
              curated_variant_list_id: 1,
              name: "CM130453",
              pathogenicity: "Unknown significance (VUS)",
            },
          ],
          custom_data: null,
          denovo_status: "ASSUMED_PARENTAL_GENOTYPE",
          depth: 32,
          exomiser_scores:
            '{"EXOMISER_VARIANT_SCORE": "0.0", "EXOMISER_GENE_PHENO_SCORE": "0.50042915", "EXOMISER_GENE_VARIANT_SCORE": "0.0", "EXOMISER_GENE_COMBINED_SCORE": "3.0759646E-4"}',
          family_comparisons: [null],
          filter: ["NO_VCF_FILTER"],
          gene_id: 5297,
          genotype: "Heterozygous",
          gnomad_exomes_ac: null,
          gnomad_exomes_ac_afr: null,
          gnomad_exomes_ac_amr: null,
          gnomad_exomes_ac_asj: null,
          gnomad_exomes_ac_eas: null,
          gnomad_exomes_ac_fin: null,
          gnomad_exomes_ac_nfe: null,
          gnomad_exomes_ac_oth: null,
          gnomad_exomes_ac_sas: null,
          gnomad_exomes_af: null,
          gnomad_exomes_af_afr: null,
          gnomad_exomes_af_amr: null,
          gnomad_exomes_af_asj: null,
          gnomad_exomes_af_eas: null,
          gnomad_exomes_af_fin: null,
          gnomad_exomes_af_nfe: null,
          gnomad_exomes_af_oth: null,
          gnomad_exomes_af_sas: null,
          inheritance: "maternal",
          loeuf_score: null,
          mes_percent_change: null,
          missense_z_score: null,
          moi_ad: 0,
          moi_arch: 0,
          moi_arh: 0,
          moi_mt: 0,
          moi_xld: 0,
          moi_xlr: 0,
          moi_xlrch: null,
          patient_id: 525,
          patient_variant_id: 2935789,
          pli_score: null,
          rf_score: null,
          score: 117.0,
          snv: {
            af_exac: 0.273189914411288,
            af_exac_afr: 0.367277992277992,
            af_exac_amr: 0.319878472222222,
            af_exac_eas: 0.187732342007435,
            af_exac_fin: 0.19047619047619,
            af_exac_nfe: 0.241279855790897,
            af_exac_sas: 0.389906587407497,
            af_kg: 0.0,
            af_max: 0.389906587407497,
            af_uk10k: 0.247843,
            alt_allele: "T",
            chr: "19",
            ref_allele: "G",
            start: 41512841,
            variant_id: 2150296,
          },
          splice_ai_max_score: null,
          ssf_percent_change: null,
          suggested_pathogenicity: null,
          variant_id: 2150296,
          variant_transcript: [
            {
              hgvs_c: "XM_005258570.1:c.516G>T",
              hgvs_p: "XP_005258627.1:p.Gln172His",
              polyphen_score: null,
              sift_score: null,
              transcript_id: 261335,
              variant_id: 2150296,
              vep_consequence: "missense_variant",
            },
          ],
        },
      ],
    },
  ],
  pager: {
    first_item: 1,
    last_item: 1,
    last_page: 1,
    total_items: 1,
  },
  success: "true",
};

export default mockSnvResponse;
