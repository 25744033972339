const editGeneResponse = {
  fields: {
    autosomal_dominant: {
      is_numeric: 0,
      data_type: "boolean",
      input: "checkbox",
      label: "Autosomal dominant",
    },
    autosomal_recessive: {
      input: "checkbox",
      data_type: "boolean",
      label: "Autosomal recessive",
      is_numeric: 0,
    },
    x_linked: {
      label: "X-linked",
      data_type: "boolean",
      input: "checkbox",
      is_numeric: 0,
    },
    transcript_id: {
      options: [
        { value: 50454, name: "ENST00000263817" },
        { name: "ENST00000439188", value: 101082 },
        { name: "NM_003742", value: 4867 },
        { value: 249989, name: "XM_005246930" },
        { name: "ENST00000478354", value: 125969 },
      ],
      data_type: "integer",
      input: "select",
      required: 1,
      label: "Preferred transcript",
      is_numeric: 1,
    },
    condition: {
      data_type: "varchar",
      input: "textarea",
      label: "Condition",
      size: 255,
    },
    testField: {
      value: "",
      label: "Optional field",
      optional: "Yes",
      dataType: "varchar",
      name: "optional_field",
      size: 255,
      input: "textarea",
    },
  },
  gene: {
    morbid_id: "601847, 605479",
    omim_id: [603201],
    name: "ABCB11",
    autosomal_dominant: 1,
    autosomal_recessive: 1,
    x_linked: 0,
    transcript_id: 4867,
    ensembl_id: "ENSG00000073734",
    condition: "",
    refseq_id: "NM_003742",
    uniprot_id: "O95342",
    gene_id: 62,
  },
  keys: [
    "gene_panel_id",
    "gene_id",
    "autosomal_recessive",
    "autosomal_dominant",
    "x_linked",
    "condition",
    "transcript_id",
    "created",
    "updated",
    "test-field",
  ],
};

export default editGeneResponse;
