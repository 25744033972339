import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

export const response = {
  project: {
    children_count: 14,
    patients_count: 15,
    project_id: PROJECT_ID_RARE_DISEASE,
    code: "Rare Disease project",
    description: "Rare Disease project",
    is_double_user_check_enabled: 0,
    users_count: 4,
    patient_reference_label: null,
    descendants_count: 17,
    is_patient_visibility_enabled: 1,
  },
  metadata: [
    { id: 304, name: "Allocated to" },
    { name: "Ethnicity ", id: 572 },
    {
      id: 576,
      name: "Person Id",
    },
    { name: "Person Type", id: 577 },
    { id: 591, name: "AAA6" },
    { id: 345, name: "reference" },
    { id: 601, name: "test_date_1" },
  ],
  patients: [
    {
      status: "Sample registered",
      reports_count: 0,
      reference: "Test patient 2",
      metadata_values: '{"601": "2021-05-29T16:15:18"}',
      phenotype_names: null,
      has_some_obsolete_phenotypes: 0,
      phenotype_summary: null,
      has_automated_annotation: 0,
      family_refs: '["Family 1"]',
      updated: 1469010743,
      cnv_analysis: "Not requested",
      reports_without_variants_count: 0,
      reports_with_variants_count: 0,
      sample_type_name: null,
      protocol_name: null,
      has_reports: 0,
      patient_id: 8741,
      gene_panel_titles: null,
      has_auto_acmg_suggestions: true,
      annotation_sources: null,
    },
    {
      reports_without_variants_count: 1,
      protocol_name: null,
      sample_type_name: null,
      reports_with_variants_count: 0,
      cnv_analysis: "Not requested",
      updated: 1590577894,
      patient_id: 525,
      has_reports: 1,
      gene_panel_titles:
        '["Imported gene panel - Paediatric Opthalmic.txt: Demo", "GPM.cerebellar_hypoplasia", "PGx genes: Demo", "PCD: Demo"]',
      metadata_values:
        '{"572": "British", "345": "ABCDE", "601": "2020-10-29T16:15:18"}',
      reference: "Demo_Child",
      phenotype_names:
        '["Increased adipose tissue around the neck", "Cerebellar hypoplasia", "Extramedullary hematopoiesis", "Abnormality of bone marrow cell morphology", "Olivopontocerebellar hypoplasia", "Abnormality of the pons", "Chorioretinal scar", "Abnormality of glossopharyngeal nerve"]',
      has_some_obsolete_phenotypes: 1,
      reports_count: 1,
      status: "Review complete",
      family_refs: '["Family 2"]',
      has_automated_annotation: 1,
      phenotype_summary: null,
      annotation_sources:
        '[{"type": "data", "value": "81", "attribute": "ENSEMBL"}]',
    },
  ],
};
