const mockExistingPanels = {
  "gene-panels": [
    {
      description: "",
      gene_panel_id: 1234,
      genes_count: 1,
      title: "Mock existing gene panel",
      ensembl_version: "81",
    },
    {
      description: "",
      gene_panel_id: 2345,
      genes_count: 100,
      title: "Another Gene Panel",
      ensembl_version: "108",
    },
  ],
  "write-permissions": 1,
};

export default mockExistingPanels;
