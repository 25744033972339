export const mockVepConsequences = {
  data: [
    { type: "vep_consequence", id: "transcript_ablation" },
    { type: "vep_consequence", id: "splice_donor_variant" },
    { type: "vep_consequence", id: "splice_acceptor_variant" },
    { type: "vep_consequence", id: "stop_gained" },
    { type: "vep_consequence", id: "frameshift_variant" },
    { type: "vep_consequence", id: "start_lost" },
    { type: "vep_consequence", id: "stop_lost" },
    { type: "vep_consequence", id: "initiator_codon_variant" },
    { type: "vep_consequence", id: "transcript_amplification" },
    { type: "vep_consequence", id: "inframe_insertion" },
    { type: "vep_consequence", id: "inframe_deletion" },
    { type: "vep_consequence", id: "missense_variant" },
    { type: "vep_consequence", id: "protein_altering_variant" },
    { type: "vep_consequence", id: "splice_region_variant" },
    { type: "vep_consequence", id: "incomplete_terminal_codon_variant" },
    { type: "vep_consequence", id: "stop_retained_variant" },
    { type: "vep_consequence", id: "synonymous_variant" },
    { type: "vep_consequence", id: "coding_sequence_variant" },
    { type: "vep_consequence", id: "mature_miRNA_variant" },
    { type: "vep_consequence", id: "5_prime_UTR_variant" },
    { type: "vep_consequence", id: "3_prime_UTR_variant" },
    { type: "vep_consequence", id: "non_coding_transcript_exon_variant" },
    { type: "vep_consequence", id: "non_coding_transcript_variant" },
    { type: "vep_consequence", id: "intron_variant" },
    { type: "vep_consequence", id: "NMD_transcript_variant" },
    { type: "vep_consequence", id: "upstream_gene_variant" },
    { type: "vep_consequence", id: "downstream_gene_variant" },
    { type: "vep_consequence", id: "TFBS_ablation" },
    { type: "vep_consequence", id: "TFBS_amplification" },
    { type: "vep_consequence", id: "TF_binding_site_variant" },
    { type: "vep_consequence", id: "regulatory_region_variant" },
    { type: "vep_consequence", id: "regulatory_region_ablation" },
    { type: "vep_consequence", id: "regulatory_region_amplification" },
    { type: "vep_consequence", id: "feature_elongation" },
    { type: "vep_consequence", id: "feature_truncation" },
    { type: "vep_consequence", id: "intergenic_variant" },
  ],
  links: { self: "http://localhost/webapi/entities/vep_consequences" },
  meta: { count: 36 },
  jsonapi: { version: "1.0" },
};
