export const reportServiceTemplates = {
  data: [
    {
      type: "report_service_template",
      attributes: {
        template_name: "Report Automation",
        updated: 1586110439,
        template_version: 1,
        created: 1586110439,
        template_description: null,
      },
      id: "fabd15a6-7afc-11ea-a0ab-ed180b69db89",
    },
  ],
  links: {
    self: "http://sapientia-python:5003/webapi/entities/projects/7/report_service_templates",
  },
  meta: { count: 1 },
  jsonapi: { version: "1.0" },
};
