import { PROJECT_ID_RARE_DISEASE, USER_ID_SYSTEM_USER } from "tests/fixtures";

export const SYSTEM_USER = {
  project: {
    patients_count: 29,
    description: "Demo project",
    is_double_user_check_enabled: 0,
    project_id: PROJECT_ID_RARE_DISEASE,
    code: "Demo",
    patient_reference_label: null,
    descendants_count: 7,
    is_patient_visibility_enabled: 1,
    children_count: 7,
    users_count: 8,
  },
  access_levels: [
    {
      only_double_user_check: 0,
      id: "write",
      user_project_access_level: "write",
      label: "Clinical data curator",
      assigned: 0,
      description: "creates patient records and modifies clinical information",
      role_name: "curator",
    },
    {
      description:
        "Upload patient sample information to trigger processing of data",
      assigned: 0,
      role_name: "submit_ir",
      user_project_access_level: null,
      only_double_user_check: 0,
      id: "submit_ir",
      label: "Submit an Interpretation Request",
    },
    {
      only_double_user_check: 0,
      id: "genecurator",
      user_project_access_level: null,
      label: "Gene Panel Curator",
      assigned: 0,
      description: "Can add and edit gene panels",
      role_name: "gene_panel_curator",
    },
    {
      assigned: 0,
      description: "Summarise report in free text for provider",
      role_name: "create_report_summary",
      only_double_user_check: 0,
      id: "create_report_summary",
      user_project_access_level: null,
      label: "Write report summary",
    },
    {
      description: "Complete review and approve final PDF",
      assigned: 0,
      role_name: "complete_review",
      user_project_access_level: null,
      only_double_user_check: 0,
      id: "complete_review",
      label: "Approve report",
    },
    {
      label: "Perform report review",
      user_project_access_level: null,
      id: "check_report",
      only_double_user_check: 0,
      role_name: "check_report",
      description: "Review report contents",
      assigned: 0,
    },
    {
      description: "Review QC metrics and coverage for QC check",
      assigned: 0,
      role_name: "check_qc",
      user_project_access_level: null,
      id: "check_qc",
      only_double_user_check: 0,
      label: "Perform QC review",
    },
    {
      assigned: 1,
      description:
        "assigns pathogenicity and contribution to phenotype of the variants",
      role_name: "assigner",
      id: "assign",
      only_double_user_check: 0,
      user_project_access_level: null,
      label: "Analyst",
    },
  ],
  project_user: {
    project_id: PROJECT_ID_RARE_DISEASE,
    is_inherited: 1,
    role: "Member",
    fullname: "System User",
    visibility_level_id: "1",
    user_id: USER_ID_SYSTEM_USER,
    access_level: "read",
    authentication_provider_id: null,
    created: 1601897458,
    updated: 1601897458,
    email: "system-user@sapientia.co.uk",
  },
};

export default {
  [USER_ID_SYSTEM_USER]: SYSTEM_USER,
};
