const genePanelGenesResponse = {
  genes: [
    {
      autosomal_dominant: 0,
      autosomal_recessive: 0,
      condition: "",
      gene_id: 28023,
      in_base_panel: 1,
      name: "SLC22A5",
      old_name: "CDSP",
      synonyms: "OCTN2, SCD",
      transcript: {
        name: "NM_003060",
        url: "http://www.ncbi.nlm.nih.gov/nuccore/NM_003060",
      },
      updated: 1679477439,
      x_linked: 0,
    },
    {
      autosomal_dominant: 0,
      autosomal_recessive: 1,
      condition: "",
      gene_id: 49,
      in_base_panel: null,
      name: "ABCA3",
      old_name: "ABC3",
      synonyms: "ABC-C, EST111653, LBM180",
      transcript: {
        name: "NM_001089",
        url: "http://www.ncbi.nlm.nih.gov/nuccore/NM_001089",
      },
      updated: 1686085082,
      x_linked: 0,
    },
    {
      autosomal_dominant: 0,
      autosomal_recessive: 0,
      condition: "",
      gene_id: 62,
      in_base_panel: null,
      name: "ABCB11",
      old_name: "BSEP, PFIC2",
      synonyms: "ABC16, SPGP, PFIC-2, PGY4",
      transcript: {
        name: "NM_003742",
        url: "http://www.ncbi.nlm.nih.gov/nuccore/NM_003742",
      },
      updated: 1686085097,
      x_linked: 0,
    },
  ],
};

export default genePanelGenesResponse;
