const mockGenePanelUploadGenes = {
  genes: [
    {
      pfam_id: "PF01411, PF02272, PF07973",
      gene_id: 31,
      ensembl_id: "ENSG00000090861",
      autosomal_dominant: null,
      gencode_id: "ENSG00000090861.9",
      chr: "16",
      orphanet_id: null,
      synonyms: null,
      transcript_name: "NM_001605",
      morbid_id: "613287",
      hgnc_id: "20",
      hi_score: 0.316,
      uploaded_gene_data:
        '{"chr": "", "name": "AARS", "x_linked": "", "autosomal_dominant": "", "autosomal_recessive": ""}',
      end: 70323446,
      autosomal_recessive: null,
      name: "AARS",
      ucsc_id: "uc002eyn.1",
      ddgene_code: null,
      x_linked: null,
      refseq_id: "NM_001605",
      pubmed_id: "8595897",
      hi_rgb: "175,80,0",
      uniprot_id: "P49588",
      old_name: null,
      uploaded_gene_name: "AARS",
      omim_id: [601065],
      description: "alanyl-tRNA synthetase",
      transcript_url: "http://www.ncbi.nlm.nih.gov/nuccore/NM_001605",
      interpro_id:
        "IPR002318, IPR003156, IPR012947, IPR018162, IPR018163, IPR018164, IPR018165",
      enzyme_id: "6.1.1.7",
      condition: "",
      hi_index: 27.9,
      default_transcript_id: 36974,
      start: 70286198,
    },
  ],
};

export default mockGenePanelUploadGenes;
