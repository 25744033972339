import { AUTH_PROVIDER_1_NAME } from "tests/fixtures";

const mockAuthenticationProvidersByUser = {
  data: [
    {
      type: "authentication_provider",
      attributes: {
        name: AUTH_PROVIDER_1_NAME,
        updated: 1551694033,
        created: 1551694033,
      },
      id: "1234",
      relationships: {},
    },
  ],
  meta: { count: 1 },
};

export default mockAuthenticationProvidersByUser;
