import { RARE_DISEASE_PROJECT_TYPE } from "modules/projects/constants";

import { PROJECT_ID_RARE_DISEASE } from "tests/fixtures";

export const response = {
  project: {
    code: "Rare Disease Project",
    curated_variant_lists: [
      {
        curated_data_schema:
          '{"type": "object", "properties": {"DB": {"type": "string", "title": "dbSNP identifier, build 137", "propertyOrder": 70}, "DNA": {"type": "string", "title": "DNA annotation", "propertyOrder": 50}, "MUT": {"type": "string", "title": "HGMD mutant allele", "propertyOrder": 20}, "GENE": {"type": "string", "title": "Gene symbol", "propertyOrder": 30}, "PHEN": {"type": "string", "title": "HGMD primary phenotype", "propertyOrder": 80}, "PROT": {"type": "string", "title": "Protein annotation", "propertyOrder": 60}, "CLASS": {"type": "string", "title": "Mutation Category", "description": "see https://portal.biobase-international.com/hgmd/pro/global.php#cats", "propertyOrder": 10}, "STRAND": {"type": "string", "title": "Gene strand", "propertyOrder": 40}}}',
        curated_variant_list_id: 1,
        description: "Description of mock CVL",
        genoverse_track_class: "Genoverse.Track.SNV.CuratedList.HGMDPro",
        name: "Name of mock CVL",
        project_id: PROJECT_ID_RARE_DISEASE,
        show_pathogenicity: 0,
        type: "Knowledgebase",
        variant_type: "SNV",
      },
    ],
    defaults: {
      coverage_stats: {
        buffer_length: 5,
        coverage_regions: "coding_only",
      },
      double_user_check: {
        enabled: false,
      },
      gene_coverage: {
        coverage: [],
        default_depth: 15,
        locked: false,
      },
      patient_visibility: {
        enabled: false,
      },
      visibility_levels: {},
    },
    defaults_is_inherited: 0,
    description: "Rare Disease project",
    features: { revel: true, splice_ai: true, tiering: false, auto_acmg: true },
    gene_panels: [
      {
        archived: 0,
        base_panel_id: null,
        created: 1412087033,
        description: null,
        external_id: null,
        gene_count: 41,
        gene_panel_id: 963,
        project_id: 7,
        title: "PCD: Demo",
        updated: 1412178620,
        version: "",
      },
      {
        archived: 0,
        base_panel_id: null,
        created: 1445856341,
        description: null,
        external_id: null,
        gene_count: 20,
        gene_panel_id: 933,
        project_id: 7,
        title: "PGx genes: Demo",
        updated: 1445856341,
        version: "",
      },
    ],
    has_custom_data_tiers: false,
    is_inherited_variant_filter_preset: 0,
    is_root: "",
    metadata_field_mapping_for_pedigree: {},
    path: `240.${PROJECT_ID_RARE_DISEASE}`,
    patient_qc_filter_strings: [
      {
        label: "HighDPFRatio",
        value: "HighDPFRatio",
      },
      {
        label: "HighDepth",
        value: "HighDepth",
      },
      {
        label: "HighSNVSB",
        value: "HighSNVSB",
      },
      {
        label: "IndelConflict",
        value: "IndelConflict",
      },
      {
        label: "LowGQX",
        value: "LowGQX",
      },
      {
        label: "PASS",
        value: "PASS",
      },
      {
        label: "SiteConflict",
        value: "SiteConflict",
      },
      {
        label: "NO_VCF_FILTER",
        value: "NO_VCF_FILTER",
      },
    ],
    patient_reference_field_config: null,
    private_af: 0,
    project_type_id: 1,
    public_patients: 0,
    research: 0,
    test_case: 0,
    total_patients: 0,
    updated: "2022-08-02T14:04:58",
    variant_panel_config: [
      [
        "gene_name",
        "gene_hi_score",
        "transcript_name",
        "base_change",
        "genotype",
        "codons",
        "amino_acid",
        "hgvs_c",
        "hgvs_p",
        "protein_position",
      ],
      [
        "vep_consequence",
        "grantham",
        "polyphen",
        "sift",
        "revel",
        "gerp",
        "splice_site",
        "inheritance",
        "comparison_outcome",
        "max_af",
        "colocated_variants",
        "other_patients",
        "depth",
        "read_split",
        "quality",
        "filter",
      ],
    ],
    variant_panel_config_is_inherited: 0,
    webhook_integration_id: null,
    project_id: PROJECT_ID_RARE_DISEASE,
    project_type_internal_name: RARE_DISEASE_PROJECT_TYPE,
  },
};
