import {
  PATIENT_ID_ONCOLOGY_QC_CHECKED,
  PATIENT_ID_ONCOLOGY_READY_FOR_REVIEW,
  PATIENT_ID_ONCOLOGY_REPORT_CHECKED,
  PATIENT_ID_ONCOLOGY_REVIEW_COMPLETE,
} from "tests/fixtures";

export default {
  [PATIENT_ID_ONCOLOGY_READY_FOR_REVIEW]: {
    valid_actions: ["fail_qc", "fail_sample", "pass_qc"],
    status: "Ready for review",
  },
  [PATIENT_ID_ONCOLOGY_QC_CHECKED]: {
    valid_actions: ["approve_report", "reject_report", "undo_qc"],
    status: "QC checked",
  },
  [PATIENT_ID_ONCOLOGY_REPORT_CHECKED]: {
    status: "Report checked",
    valid_actions: ["complete_review", "undo_check_report"],
  },
  [PATIENT_ID_ONCOLOGY_REVIEW_COMPLETE]: {
    status: "Review complete",
    valid_actions: [],
  },
};
