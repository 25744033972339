const cvlAuditResponse = {
  data: [
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: null,
        action_entity: "curated_variant_list",
        related_data: {
          name: "Some CVL",
          is_automated_annotation_decisions: 1,
        },
        user_action: "create",
        user_email: "superadmin@test.com",
        user_name: "superadmin",
        created: 1598282782,
      },
      id: "9997",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: null,
        action_entity: "curated_variant_list",
        related_data: {
          name: "Some CVL",
          is_automated_annotation_decisions: 1,
        },
        delta_for_ui: {
          values: {
            description: {
              before: "descr before",
              after: "descr after",
            },
            status: {
              before: "pending",
              after: "processing_complete",
            },
          },
        },
        user_action: "update",
        user_email: "superadmin@test.com",
        user_name: "superadmin",
        created: 1598282782,
      },
      id: "9996",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: null,
        action_entity: "curated_variant_list",
        related_data: {
          name: "New name",
          is_automated_annotation_decisions: 1,
        },
        delta_for_ui: {
          values: {
            name: {
              before: "Some CVL",
              after: "New name",
            },
          },
        },
        user_action: "update",
        user_email: "superadmin@test.com",
        user_name: "superadmin",
        created: 1601683202,
      },
      id: "9995",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "Imported from CSV",
          gene_name: "GENE2",
          pathogenicity: "Benign",
        },
        user_action: "create",
        user_email: "adder@user.email",
        user_name: "User added the CV",
        created: 1601683210,
      },
      id: "9998",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "Imported from CSV",
          gene_name: "GENE2",
          pathogenicity: "Pathogenic",
        },
        delta_for_ui: {
          values: {
            pathogenicity: {
              before: "Benign",
              after: "Pathogenic",
            },
          },
          curated_data_values: {
            G5: {
              before: "TRUE",
              after: "FALSE",
            },
            RS: {
              before: 201709278,
              after: 201709279,
            },
            dbSNPBuildID: {
              before: 123,
              after: 456,
            },
            CLNACC: {
              before: ["RCV000030048.1"],
              after: ["RCV000030048.1", "RCV000030048.2"],
            },
            CLNSRC: {
              before: ["."],
              after: ["123123"],
            },
          },
        },
        user_action: "update",
        user_email: "updater@user.email",
        user_name: "User updated the CV",
        created: 1601683220,
      },
      id: "10001",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "Imported from CSV",
          gene_name: "GENE2",
          pathogenicity: "Pathogenic",
        },
        delta_for_ui: {
          curated_data_values: {
            G5: {
              before: "FALSE",
              after: "TRUE",
            },
          },
        },
        user_action: "update",
        user_email: "updater@user.email",
        user_name: "User updated the CV",
        created: 1601683220,
      },
      id: "10002",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "From CSV",
          gene_name: "GENE2",
          pathogenicity: "Pathogenic",
        },
        delta_for_ui: {
          values: {
            name: {
              before: "Imported from CSV",
              after: "From CSV",
            },
          },
        },
        user_action: "update",
        user_email: "updater@user.email",
        user_name: "User updated the CV",
        created: 1601683220,
      },
      id: "10003",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "HG58158",
          patient_reference: "HDJMI8855",
          patient_id: 526,
          gene_name: "GENE1",
          pathogenicity: "Benign",
        },
        user_action: "create",
        user_email: "adder@user.email",
        user_name: "User added the CV",
        created: 1601683210,
      },
      id: "9998",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "HG58158",
          patient_reference: "HDJMI8855",
          patient_id: 526,
          gene_name: "GENE1",
          pathogenicity: "Pathogenic",
        },
        delta_for_ui: {
          values: {
            pathogenicity: {
              before: "Benign",
              after: "Pathogenic",
            },
          },
        },
        user_action: "update",
        user_email: "updater@user.email",
        user_name: "User updated the CV",
        created: 1601683220,
      },
      id: "9999",
      type: "curated_variant_list_audit",
    },
    {
      attributes: {
        curated_variant_list_id: "555",
        curated_variant_id: "25586",
        action_entity: "curated_variant",
        related_data: {
          name: "HG_NEW_NAME",
          patient_reference: "HDJMI8855",
          patient_id: 526,
          gene_name: "GENE1",
          pathogenicity: "Pathogenic",
        },
        delta_for_ui: {
          values: {
            name: {
              before: "HG58158",
              after: "HG_NEW_NAME",
            },
          },
        },
        user_action: "update",
        user_email: "updater@user.email",
        user_name: "User updated the CV",
        created: 1601683220,
      },
      id: "10000",
      type: "curated_variant_list_audit",
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/projects/7/curated_variant_lists/23/audit?origin_project_id=240&page[size]=10&page[number]=1&sort=user_name&filter=1234&date_from=2020-08-22T18:47:55.121341+00:00&date_to=2020-08-22T18:47:55.121341+00:00",
  },
  meta: {
    count: 10,
  },
  jsonapi: {
    version: "1.0",
  },
};

export default cvlAuditResponse;
