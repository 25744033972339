const mockOncologySequenceVariants = {
  data: [
    {
      type: "oncology_snv_variants",
      id: "chr1|64879025|C|CT",
      attributes: {
        chrom: "chr1",
        ref: "C",
        gene_type: "ONC",
        pos: 64879025,
        alt: "CT",
        vaf: 81.82,
        variant_type: "splice region variant, frameshift variant",
        gene: "JAK1",
        coding_change: "c.328dupA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr2|25244615|T|TC",
      attributes: {
        chrom: "chr2",
        ref: "T",
        gene_type: "TSG",
        pos: 25244615,
        alt: "TC",
        vaf: 27.78,
        variant_type: "frameshift variant",
        gene: "DNMT3A",
        coding_change: "c.1591dupG",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr3|37040210|AC|A",
      attributes: {
        chrom: "chr3",
        ref: "AC",
        gene_type: "TSG",
        pos: 37040210,
        alt: "A",
        vaf: 30,
        variant_type: "frameshift variant",
        gene: "MLH1",
        coding_change: "c.1584delC",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr9|95506562|CT|C",
      attributes: {
        chrom: "chr9",
        ref: "CT",
        gene_type: "TSG",
        pos: 95506562,
        alt: "C",
        vaf: 50,
        variant_type: "frameshift variant",
        gene: "PTCH1",
        coding_change: "c.238delA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr11|108292786|AG|A",
      attributes: {
        chrom: "chr11",
        ref: "AG",
        gene_type: "TSG",
        pos: 108292786,
        alt: "A",
        vaf: 63.33,
        variant_type: "frameshift variant",
        gene: "ATM",
        coding_change: "c.4605delG",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr11|118496328|GT|G",
      attributes: {
        chrom: "chr11",
        ref: "GT",
        gene_type: "TO",
        pos: 118496328,
        alt: "G",
        vaf: 100,
        variant_type: "frameshift variant",
        gene: "KMT2A",
        coding_change: "c.5617delT",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr12|120994176|C|CT",
      attributes: {
        chrom: "chr12",
        ref: "C",
        gene_type: "TSG",
        pos: 120994176,
        alt: "CT",
        vaf: 100,
        variant_type: "frameshift variant",
        gene: "HNF1A",
        coding_change: "c.726_727insT",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr13|32333142|C|CT",
      attributes: {
        chrom: "chr13",
        ref: "C",
        gene_type: "TSG",
        pos: 32333142,
        alt: "CT",
        vaf: 41.67,
        variant_type: "frameshift variant",
        gene: "BRCA2",
        coding_change: "c.1664_1665insT",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr13|32338093|T|TA",
      attributes: {
        chrom: "chr13",
        ref: "T",
        gene_type: "TSG",
        pos: 32338093,
        alt: "TA",
        vaf: 34.29,
        variant_type: "frameshift variant",
        gene: "BRCA2",
        coding_change: "c.3739dupA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr13|32338095|T|TA",
      attributes: {
        chrom: "chr13",
        ref: "T",
        gene_type: "TSG",
        pos: 32338095,
        alt: "TA",
        vaf: 22.22,
        variant_type: "frameshift variant",
        gene: "BRCA2",
        coding_change: "c.3740_3741insA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr17|12141197|C|CA",
      attributes: {
        chrom: "chr17",
        ref: "C",
        gene_type: "TSG",
        pos: 12141197,
        alt: "CA",
        vaf: 33.33,
        variant_type: "frameshift variant",
        gene: "MAP2K4",
        coding_change: "c.1137_1138insA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr17|31201121|TG|T",
      attributes: {
        chrom: "chr17",
        ref: "TG",
        gene_type: "TSG",
        pos: 31201121,
        alt: "T",
        vaf: 45.45,
        variant_type: "frameshift variant",
        gene: "NF1",
        coding_change: "c.1148delG",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr17|31330422|TA|T",
      attributes: {
        chrom: "chr17",
        ref: "TA",
        gene_type: "TSG",
        pos: 31330422,
        alt: "T",
        vaf: 100,
        variant_type: "frameshift variant",
        gene: "NF1",
        coding_change: "c.5675delA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr19|17826905|G|GT",
      attributes: {
        chrom: "chr19",
        ref: "G",
        gene_type: "TO",
        pos: 17826905,
        alt: "GT",
        vaf: 40.91,
        variant_type: "frameshift variant",
        gene: "JAK3",
        coding_change: "c.3212dupA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr20|32436360|GAC|G",
      attributes: {
        chrom: "chr20",
        ref: "GAC",
        gene_type: "TSG",
        pos: 32436360,
        alt: "G",
        vaf: 51.52,
        variant_type: "frameshift variant",
        gene: "ASXL1",
        coding_change: "c.3635_3636delCA",
        protein_change: "",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr1|46066962|G|C",
      attributes: {
        chrom: "chr1",
        ref: "G",
        gene_type: "TO",
        pos: 46066962,
        alt: "C",
        vaf: 10.03,
        variant_type: "stop gained",
        gene: "PIK3R3",
        coding_change: "c.444C>G",
        protein_change: "p.Tyr148X",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr3|41224642|CCTT|C",
      attributes: {
        chrom: "chr3",
        ref: "CCTT",
        gene_type: "ONC",
        pos: 41224642,
        alt: "C",
        vaf: 5.71,
        variant_type: "inframe deletion",
        gene: "CTNNB1",
        coding_change: "c.133_135delTCT",
        protein_change: "p.Ser45del",
      },
    },
    {
      type: "oncology_snv_variants",
      id: "chr3|185428738|C|T",
      attributes: {
        chrom: "chr3",
        ref: "C",
        gene_type: "TSG",
        pos: 185428738,
        alt: "T",
        vaf: 29.06,
        variant_type: "stop gained",
        gene: "MAP3K13",
        coding_change: "c.157C>T",
        protein_change: "p.Arg53X",
      },
    },
  ],
  links: {
    self: "http://localhost/webapi/entities/patients/14985/oncology/variants/snvs",
  },
  meta: {
    count: 18,
  },
  jsonapi: {
    version: "1.0",
  },
};

export default mockOncologySequenceVariants;
