export const emptyPatientsMetadata = {
  data: [],
  meta: { count: 9 },
  jsonapi: { version: "1.0" },
};

export const patientsMetadata = {
  data: [
    {
      project_field_config: {},
      required: 0,
      field_name: "Allocated to",
      category_name: null,
      project_code: "Demo",
      field_config: {},
      inherited: 0,
      project_id: 7,
      field_type: "Text",
      field_code: "allocated_to",
      position: 0,
      patient_metadata_field_id: 304,
      visible_in_list: 1,
    },
    {
      visible_in_list: 0,
      patient_metadata_field_id: 542,
      position: 1,
      field_code: "phenotype_summary",
      field_type: "TextArea",
      project_id: 240,
      inherited: 1,
      field_config: {
        type: "TextArea",
      },
      category_name: null,
      project_code: "Congenica",
      field_name: "Phenotype summary",
      required: 0,
      project_field_config: {
        type: "TextArea",
      },
    },
    {
      inherited: 0,
      project_id: 7,
      field_config: {
        type: "Select::Ethnicity",
        required: 1,
      },
      field_name: "Ethnicity ",
      category_name: null,
      project_code: "Demo",
      patient_metadata_field_id: 572,
      visible_in_list: 1,
      position: 2,
      field_code: "ETH",
      field_type: "Select::Ethnicity",
      required: 1,
      project_field_config: {
        required: 1,
        type: "Select::Ethnicity",
      },
    },
    {
      project_field_config: {
        type: "Select::ParentalPhenotype",
        required: false,
      },
      required: 0,
      field_type: "Select::ParentalPhenotype",
      field_code: "parental_phenotype",
      position: 6,
      patient_metadata_field_id: 656,
      visible_in_list: 1,
      field_name: "ParentalPhenotype",
      category_name: null,
      project_code: "Demo",
      field_config: {
        required: false,
        type: "Select::ParentalPhenotype",
      },
      project_id: 7,
      inherited: 0,
    },
    {
      field_type: "Date",
      field_code: "date_field",
      position: 9,
      patient_metadata_field_id: 660,
      visible_in_list: 1,
      project_code: "Demo",
      category_name: null,
      field_name: "Date field",
      field_config: {
        required: null,
        type: "Date",
      },
      inherited: 0,
      project_id: 7,
      project_field_config: {
        type: "Date",
        required: null,
      },
      required: 0,
    },
    {
      field_type: "Select::Age",
      project_field_config: {
        required: true,
        type: "Select::Age",
      },
      required: true,
      project_code: "Demo",
      category_name: null,
      field_name: "age field",
      project_id: 7,
      inherited: 0,
      field_config: {
        required: true,
        type: "Select::Age",
      },
      field_code: "age_field",
      patient_metadata_field_id: 661,
      visible_in_list: 1,
      position: 10,
    },
    {
      visible_in_list: 1,
      patient_metadata_field_id: 662,
      position: 11,
      field_code: "sex_field",
      field_type: "Select::Sex",
      inherited: 0,
      project_id: 7,
      field_config: {
        required: null,
        type: "Select::Sex",
      },
      field_name: "sex field",
      category_name: null,
      project_code: "Demo",
      required: 0,
      project_field_config: {
        type: "Select::Sex",
        required: null,
      },
    },
  ],
};
