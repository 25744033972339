import {
  PATIENT_ID_ONCOLOGY_READY_FOR_REVIEW,
  PATIENT_ID_ONCOLOGY_QC_CHECKED,
  PATIENT_ID_ONCOLOGY_REPORT_CHECKED,
  PATIENT_ID_ONCOLOGY_REVIEW_COMPLETE,
  PATIENT_ID_RD_ENSEMBL_81,
  PROJECT_ID_RARE_DISEASE,
  PROJECT_ID_ONCOLOGY,
} from "tests/fixtures";

const getMockPatient = ({
  patientId,
  projectId,
}: {
  patientId: number;
  projectId: number;
}) => ({
  tabs: {
    variants_short_tandem_repeats: true,
    genes_coverage: true,
    variants: true,
    qc: true,
    family: true,
    reports: true,
    variants_sequence: true,
    custom_iframe: true,
    audit: true,
    oncology_qc: true,
    oncology_explore: true,
    oncology_report: true,
    variants_structural: true,
    genotype: true,
  },
  patient: {
    // Properties controlled by parameters:
    project_id: projectId,
    patient_id: patientId,
    reference: `Mock Patient ${patientId}`,

    // All other properties, same for all patients at this time:
    interpretation_request_id: 191,
    feature_data_fields: {
      splice_ai: [
        "splice_ai_delta_position",
        "splice_ai_delta_score",
        "splice_ai_max_score",
      ],
      snv_annotation_file: [
        "af_esp_aa",
        "af_esp_all",
        "af_esp_ea",
        "af_kg",
        "af_kg_afr",
        "af_kg_amr",
        "af_kg_asn",
        "af_kg_eur",
        "af_kg_max",
        "af_max",
        "af_uk10k",
        "alt_allele",
        "alt_allele_fraction",
        "amino_acid",
        "canonical",
        "chr",
        "codons",
        "cosmic_id",
        "denovo_status",
        "depth",
        "domains",
        "esp_id",
        "filter",
        "genotype",
        "gerp",
        "heteroplasmy",
        "hgmd_id",
        "hgvs_c",
        "hgvs_p",
        "inheritance",
        "moi_ad",
        "moi_arch",
        "moi_arh",
        "moi_mt",
        "moi_xld",
        "moi_xlr",
        "non_mendelian",
        "patient_id",
        "polyphen_prediction",
        "polyphen_score",
        "protein_position",
        "pubmed_id",
        "readsplit",
        "ref_allele",
        "rs_id",
        "score",
        "sift_prediction",
        "sift_score",
        "start",
        "uniprot_acc_id",
        "vep_consequence",
      ],
    },
    created: "2021-04-16T09:01:12",
    person_id: 2318,
    gene_panels: [
      {
        description: "PCD: Demo - description",
        external_id: "568f871422c1fc1c79ca176d",
        gene_panel_id: 933,
        gene_to_gene_panel: [],
        title: "PCD: Demo",
        version: "1",
      },
    ],
    visibility_level_id: "1",
    qc_filter_strings: [],
    status: "Primary review",
    public: 0,
    has_str_custom_data_tiers: false,
    cache_columns: {
      moi_mt: { column: "moi_mt", type: "boolean" },
      inheritance: { type: "core.variant_inheritance", column: "inheritance" },
      sift_score_max: { type: "double precision", column: "sift_score_max" },
      exomiser_rank: { column: "exomiser_rank", type: "integer" },
      denovo_status: {
        column: "denovo_status",
        type: "core.variant_denovo_status",
      },
      af_exac_eas: { column: "af_exac_eas", type: "double precision" },
      alt_allele: { type: "ref.sequence", column: "alt_allele" },
      exomiser_scores: { column: "exomiser_scores", type: "jsonb" },
      score: { type: "double precision", column: "score" },
      sift_score_min: { column: "sift_score_min", type: "double precision" },
      af_exac_sas: { type: "double precision", column: "af_exac_sas" },
      af_max: { column: "af_max", type: "double precision" },
      moi_xlrch: { column: "moi_xlrch", type: "boolean" },
      exomiser_order: { type: "integer", column: "exomiser_order" },
      report_category: { column: "report_category", type: "character varying" },
      moi_xld: { column: "moi_xld", type: "boolean" },
      polyphen_score_max: {
        type: "double precision",
        column: "polyphen_score_max",
      },
      moi_arh: { type: "boolean", column: "moi_arh" },
      genotype: { type: "core.variant_genotype", column: "genotype" },
      pathogenicity_transcript_id: {
        type: "integer",
        column: "pathogenicity_transcript_id",
      },
      af_exac_afr: { type: "double precision", column: "af_exac_afr" },
      pathogenicity: {
        column: "pathogenicity",
        type: "core.variant_pathogenicity",
      },
      polyphen_score_min: {
        column: "polyphen_score_min",
        type: "double precision",
      },
      af_exac_fin: { type: "double precision", column: "af_exac_fin" },
      chr: { type: "ref.chromosome", column: "chr" },
      family_comparisons: {
        type: "core.variant_comparison_relationship[]",
        column: "family_comparisons",
      },
      vep_consequences: {
        type: "character varying[]",
        column: "vep_consequences",
      },
      contribution_to_phenotype: {
        column: "contribution_to_phenotype",
        type: "core.variant_contribution_to_phenotype",
      },
      start: { type: "bigint", column: "start" },
      non_mendelian: { type: "boolean", column: "non_mendelian" },
      readsplit: { column: "readsplit", type: "character varying" },
      excluded: { column: "excluded", type: "boolean" },
      filter: { column: "filter", type: "character varying[]" },
      af_exac_nfe: { column: "af_exac_nfe", type: "double precision" },
      gene_id: { column: "gene_id", type: "integer" },
      ref_allele: { type: "ref.sequence", column: "ref_allele" },
      moi_ad: { column: "moi_ad", type: "boolean" },
      moi_xlr: { column: "moi_xlr", type: "boolean" },
      gene_name: { type: "character varying", column: "gene_name" },
      moi_arch: { column: "moi_arch", type: "boolean" },
      af_exac: { column: "af_exac", type: "double precision" },
      af_kg: { type: "double precision", column: "af_kg" },
      af_uk10k: { column: "af_uk10k", type: "double precision" },
      custom_data: { type: "jsonb", column: "custom_data" },
      af_exac_amr: { type: "double precision", column: "af_exac_amr" },
      depth: { type: "integer", column: "depth" },
    },
    updated: "2021-09-24T10:37:43",
    has_auto_acmg_suggestions: false,
    is_proband: 1,
    splicing_filters: {
      cssf: false,
      max_ent_scan: false,
      ada: false,
      rf: false,
      splice_ai: false,
    },
    has_gnomad_constraint_scores: false,
    data_columns: {
      revel: {
        columns: [{ name: "revel_score", revel_vcf: true }],
        available_sources: { revel_vcf: true },
      },
      splice_ai: {
        available_sources: { database: true },
        columns: [
          { name: "splice_ai_delta_position", database: true },
          { name: "splice_ai_delta_score", database: true },
          { database: true, name: "splice_ai_max_score" },
        ],
      },
      snv_annotation_file: {
        columns: [
          { name: "af_esp_aa", database: true },
          { database: true, name: "af_esp_all" },
          { name: "af_esp_ea", database: true },
          { database: true, name: "af_kg" },
          { name: "af_kg_afr", database: true },
          { database: true, name: "af_kg_amr" },
          { database: true, name: "af_kg_asn" },
          { name: "af_kg_eur", database: true },
          { database: true, name: "af_kg_max" },
          { database: true, name: "af_max" },
          { database: true, name: "af_uk10k" },
          { database: true, name: "alt_allele" },
          { database: true, name: "alt_allele_fraction" },
          { name: "amino_acid", database: true },
          { name: "canonical", database: true },
          { database: true, name: "chr" },
          { name: "codons", database: true },
          { name: "cosmic_id", database: true },
          { name: "denovo_status", database: true },
          { name: "depth", database: true },
          { name: "domains", database: true },
          { name: "esp_id", database: true },
          { name: "filter", database: true },
          { database: true, name: "genotype" },
          { name: "gerp", database: true },
          { database: true, name: "heteroplasmy" },
          { name: "hgmd_id", database: true },
          { database: true, name: "hgvs_c" },
          { name: "hgvs_p", database: true },
          { database: true, name: "inheritance" },
          { database: true, name: "moi_ad" },
          { name: "moi_arch", database: true },
          { database: true, name: "moi_arh" },
          { name: "moi_mt", database: true },
          { database: true, name: "moi_xld" },
          { name: "moi_xlr", database: true },
          { name: "non_mendelian", database: true },
          { database: true, name: "patient_id" },
          { name: "polyphen_prediction", database: true },
          { name: "polyphen_score", database: true },
          { database: true, name: "protein_position" },
          { name: "pubmed_id", database: true },
          { database: true, name: "readsplit" },
          { database: true, name: "ref_allele" },
          { name: "rs_id", database: true },
          { database: true, name: "score" },
          { database: true, name: "sift_prediction" },
          { name: "sift_score", database: true },
          { name: "start", database: true },
          { database: true, name: "uniprot_acc_id" },
          { database: true, name: "vep_consequence" },
        ],
        available_sources: { database: true },
      },
    },
    has_revel_score: false,
    user_id: null,
    cache_version_id: 2,
    sample_type_id: 1,
    is_cnv_analysis_requested: 0,
    phenotype_summary: null,
    protocol_id: 1,
    has_custom_data_tiers: false,
    custom_data: "{}",
    thirdparty_software_versions: {
      CONGENICA_AI: "v5.4.9_7.1",
      DBSC_SNV: "1.0",
      MAST: "4.10.0_4",
      MAXENTSCAN: "1.1",
      SPLICE_AI: "1.3.1",
      SSF: "1.0",
    },
  },
});

export default {
  [PATIENT_ID_RD_ENSEMBL_81]: getMockPatient({
    patientId: PATIENT_ID_RD_ENSEMBL_81,
    projectId: PROJECT_ID_RARE_DISEASE,
  }),
  [PATIENT_ID_ONCOLOGY_READY_FOR_REVIEW]: getMockPatient({
    patientId: PATIENT_ID_ONCOLOGY_READY_FOR_REVIEW,
    projectId: PROJECT_ID_ONCOLOGY,
  }),
  [PATIENT_ID_ONCOLOGY_QC_CHECKED]: getMockPatient({
    patientId: PATIENT_ID_ONCOLOGY_QC_CHECKED,
    projectId: PROJECT_ID_ONCOLOGY,
  }),
  [PATIENT_ID_ONCOLOGY_REPORT_CHECKED]: getMockPatient({
    patientId: PATIENT_ID_ONCOLOGY_REPORT_CHECKED,
    projectId: PROJECT_ID_ONCOLOGY,
  }),
  [PATIENT_ID_ONCOLOGY_REVIEW_COMPLETE]: getMockPatient({
    patientId: PATIENT_ID_ONCOLOGY_REVIEW_COMPLETE,
    projectId: PROJECT_ID_ONCOLOGY,
  }),
};
