const geneSearchResponse = {
  panel_genes: {
    SLC22A5: {
      gencode_id: "ENSG00000197375.7",
      refseq_id: "NM_003060",
      end: 131731306,
      chr: "5",
      entrez_id: "6584",
      ensembl_id: "ENSG00000197375",
      start: 131705444,
      hi_index: 56.5,
      uniprot_id: "O76082",
      pfam_id: "PF00083, PF07690",
      lrg_id: null,
      enzyme_id: null,
      omim_id: [603377],
      ucsc_id: "uc003kww.4",
      old_name: "CDSP",
      synonyms: "OCTN2, SCD",
      hgnc_id: "10969",
      default_transcript_id: 12463,
      hi_score: 0.143,
      gene_id: 28023,
      orphanet_id: null,
      interpro_id: "IPR004749, IPR005828, IPR011701, IPR016196, IPR020846",
      morbid_id: "212140",
      description:
        "solute carrier family 22 (organic cation/carnitine transporter), member 5",
      hi_rgb: "100,155,0",
      ddgene_code: "Y",
      pubmed_id: "9618255, 9916797, 9685390",
      name: "SLC22A5",
    },
    ABCA3: {
      hgnc_id: "33",
      synonyms: "ABC-C, EST111653, LBM180",
      old_name: "ABC3",
      ucsc_id: "uc002cpy.1",
      interpro_id: "IPR003439, IPR003593, IPR027417",
      orphanet_id: null,
      gene_id: 49,
      hi_score: 0.117,
      default_transcript_id: 35556,
      morbid_id: "610921",
      description: "ATP-binding cassette, sub-family A (ABC1), member 3",
      name: "ABCA3",
      pubmed_id: "8706931",
      hi_rgb: "75,180,0",
      ddgene_code: null,
      ensembl_id: "ENSG00000167972",
      entrez_id: "21",
      end: 2390747,
      chr: "16",
      refseq_id: "NM_001089",
      gencode_id: "ENSG00000167972.8",
      uniprot_id: "Q99758",
      hi_index: 65.8,
      start: 2325882,
      omim_id: [601615],
      enzyme_id: null,
      lrg_id: null,
      pfam_id: "PF00005",
    },
    ABCB11: {
      hgnc_id: "42",
      synonyms: "ABC16, SPGP, PFIC-2, PGY4",
      old_name: "BSEP, PFIC2",
      ucsc_id: "uc002ueo.1",
      interpro_id: "IPR001140, IPR003439, IPR003593, IPR011527, IPR027417",
      gene_id: 62,
      orphanet_id: null,
      hi_score: 0.099,
      default_transcript_id: 4867,
      morbid_id: "601847, 605479",
      description: "ATP-binding cassette, sub-family B (MDR/TAP), member 11",
      name: "ABCB11",
      pubmed_id: "9806540",
      hi_rgb: "50,205,0",
      ddgene_code: "Y",
      ensembl_id: "ENSG00000073734",
      entrez_id: "8647",
      chr: "2",
      end: 169887832,
      refseq_id: "NM_003742",
      gencode_id: "ENSG00000073734.8",
      uniprot_id: "O95342",
      hi_index: 73.2,
      start: 169779448,
      omim_id: [603201],
      enzyme_id: null,
      lrg_id: null,
      pfam_id: "PF00005, PF00664",
    },
  },
  genes: [
    {
      synonyms: null,
      old_name: null,
      gene_id: 13,
      name: "AAAS",
    },
    {
      gene_id: 17030,
      name: "NAAA",
      old_name: "ASAHL",
      synonyms: null,
    },
    {
      gene_id: 62,
      name: "ABCB11",
      old_name: "BSEP, PFIC2",
      synonyms: "ABC16, SPGP, PFIC-2, PGY4",
    },
    {
      name: "SLC1A5",
      gene_id: 27996,
      old_name: "RDRC, M7V1",
      synonyms: "AAAT, ASCT2",
    },
    {
      old_name: null,
      synonyms: "PXAAA1, PAF-2",
      gene_id: 19737,
      name: "PEX6",
    },
  ],
};

export default geneSearchResponse;
