const protocols = {
  data: [
    {
      type: "protocol",
      attributes: {
        kit_name: null,
        created: 1650312330,
        bed_file_name: "Twist_Target_GRCh37.bed",
        updated: 1650312330,
        description: null,
        name: "Epilepsy",
      },
      id: "1",
    },
  ],
};

export default protocols;
