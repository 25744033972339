const filters = {
  bayes_factor: { title: "Bayes factor" },
  genotype: {
    multiple: 1,
    title: "Genotype",
    values: [
      { label: "Homozygous", value: "Homozygous" },
      { label: "Heterozygous", value: "Heterozygous" },
      { label: "Hemizygous", value: "Hemizygous" },
      { label: "Not Specified", value: "Not Specified" },
    ],
  },
  gnomad_af: { title: "gnomAD Allele Frequency" },
  inheritance: {
    multiple: 1,
    title: "Inheritance",
    values: [
      { label: "paternal", value: "paternal" },
      { label: "maternal", value: "maternal" },
      { label: "biparental", value: "biparental" },
      { label: "de-novo", value: "de-novo" },
      { label: "phase unknown", value: "phase unknown" },
    ],
  },
  quality: {
    title: "Quality",
    values: [
      { label: "Any quality", value: 0 },
      { label: "Over 50", value: 50 },
      { label: "Over 100", value: 100 },
      { label: "Over 200", value: 200 },
      { label: "Over 300", value: 300 },
      { label: "Over 400", value: 400 },
      { label: "Over 500", value: 500 },
      { label: "Over 600", value: 600 },
      { label: "Over 700", value: 700 },
      { label: "Over 800", value: 800 },
      { label: "Over 900", value: 900 },
      { label: "Over 1000", value: 1000 },
    ],
  },
  size: {
    title: "Size",
    values: [
      { label: "Any Size", value: 0 },
      { label: "Over 100bp", value: 100 },
      { label: "Over 2kbp", value: 2000 },
      { label: "Over 10kbp", value: 10000 },
      { label: "Over 25kbp", value: 25000 },
      { label: "Over 50kbp", value: 50000 },
      { label: "Over 100kbp", value: 100000 },
      { label: "Over 200kbp", value: 200000 },
      { label: "Over 500kbp", value: 500000 },
      { label: "Over 1Mbp", value: 1000000 },
      { label: "Over 5Mbp", value: 5000000 },
    ],
  },
  subtype: {
    multiple: 1,
    title: "Type",
    values: [
      { label: "SNP", value: "SNP" },
      { label: "InDel", value: "InDel" },
      { label: "Insertion", value: "Insertion" },
      { label: "Deletion", value: "Deletion" },
      { label: "CNV gain", value: "CNV gain" },
      { label: "CNV loss", value: "CNV loss" },
      { label: "Duplication", value: "Duplication" },
      { label: "Translocation", value: "Translocation" },
      { label: "Inversion", value: "Inversion" },
    ],
  },
  tier: {
    multiple: 1,
    title: "Tier",
    value: "TIERA",
    values: [
      { label: "TIERA", value: "TIERA" },
      { label: "TIERB", value: "TIERB" },
      { label: "TIERNULL", value: "TIERNULL" },
    ],
  },
};

export default filters;
