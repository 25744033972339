const fastqcJson = {
  "29951-DI_S20_L002_R2_001.fastq.json": {
    version: "0.11.3",
  },
  "29951-DI_S20_L001_R2_001.fastq.json": {
    version: "0.11.4",
  },
  "29951-DI_S20_L002_R1_001.fastq.json": {
    version: "0.11.5",
  },
  "29951-DI_S20_L001_R1_001.fastq.json": {
    version: "0.11.6",
  },
};

export default fastqcJson;
