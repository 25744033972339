const hpoTerms = {
  data: [
    {
      acc: "HP:0000118",
      child_id: 118,
      name: "Phenotypic abnormality",
      parent_id: 1,
    },
    {
      acc: "HP:0000707",
      child_id: 707,
      name: "Abnormality of the nervous system",
      parent_id: 118,
    },
    {
      acc: "HP:0012638",
      child_id: 12638,
      name: "Abnormality of nervous system physiology",
      parent_id: 707,
    },
    {
      acc: "HP:0001298",
      child_id: 1298,
      name: "Encephalopathy",
      parent_id: 12638,
    },
    {
      acc: "HP:0200134",
      child_id: 200134,
      name: "Epileptic encephalopathy",
      parent_id: 1298,
    },
    { child_id: 246, acc: "HP:0000246", name: "Sinusitis", parent_id: 12649 },
    { child_id: 246, name: "Sinusitis", acc: "HP:0000246", parent_id: 245 },
  ],
};

export default hpoTerms;
