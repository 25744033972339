const genePanelPatientsResponse = {
  gene_panel_patients: { "110": 1, "455": 1, "525": 1, "526": 1, "527": 1 },
  gene_panel_write_permission: "gene_panel_curator",
  project_patients: [
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["NIST8392"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp Epileptic_encephalopathy", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 6172,
      phenotype_names:
        '["Polycystic kidney dysplasia", "Nephrolithiasis", "Abnormality of the thyroid gland", "Hypertension", "Hemangioma", "Hyperkalemia", "Vertigo", "Pancreatic pseudocyst", "Gastritis", "Polycystic liver disease", "Sleep-wake cycle disturbance", "Recurrent cystitis", "Ulcerative colitis"]',
      phenotype_summary: null,
      protocol_name: null,
      reference: "HG004",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Primary review",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["DemoPed"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp - Cerebellar_hypoplasia", "GPM.cerebellar_hypoplasia", "PCH_exomiser_OMIM_top_genes: Demo", "Cerebellar hypoplasia - High Evidence (demo)"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 527,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "Demo_Mother",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Primary review",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["DemoPed"]',
      gene_panel_titles: '["PanelApp - Cerebellar_hypoplasia", "test"]',
      has_auto_acmg_suggestions: 1,
      has_automated_annotation: 0,
      has_reports: 1,
      has_some_obsolete_phenotypes: 0,
      metadata_values: '{"572": "British"}',
      neoplastic_cellularity: null,
      patient_id: 525,
      phenotype_names:
        '["Abnormality of head or neck", "Increased adipose tissue around the neck", "Cerebellar hypoplasia", "Extramedullary hematopoiesis", "Abnormality of bone marrow cell morphology", "Olivopontocerebellar hypoplasia", "Abnormality of the pons", "Chorioretinal scar", "Abnormality of glossopharyngeal nerve"]',
      phenotype_summary: null,
      protocol_name: null,
      reference: "Demo_Child",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Primary review",
      tumour_type: { oncotree_code: null },
      updated: 1666547472,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["LP2000274-DNA_G11"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp Epileptic_encephalopathy", "DDG2P_27Apr15", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 463,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "LP2000712-DNA_B07",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Secondary review",
      tumour_type: { oncotree_code: null },
      updated: 1648197527,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["LP2000274-DNA_G11"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp Epileptic_encephalopathy", "DDG2P_27Apr15", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 464,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "LP2000712-DNA_D03",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Review complete",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["Family 1"]',
      gene_panel_titles:
        '["Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 8741,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "Test patient 2",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Sample registered",
      tumour_type: { oncotree_code: null },
      updated: 1469010743,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: null,
      gene_panel_titles:
        '["PGx genes: Demo", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 6173,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "TG0468",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Processing",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: null,
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp - Cerebellar_hypoplasia", "GPM.cerebellar_hypoplasia", "PCD: Demo", "Primary ciliary disorders: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 1,
      has_some_obsolete_phenotypes: 0,
      metadata_values: '{"542": "Primary Ciliary Dyskinesia"}',
      neoplastic_cellularity: null,
      patient_id: 110,
      phenotype_names:
        '["Chronic otitis media", "Recurrent upper respiratory tract infections", "Ankyloblepharon", "Abnormality of glossopharyngeal nerve"]',
      phenotype_summary: "Primary Ciliary Dyskinesia",
      protocol_name: null,
      reference: "EGAN00001015155",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Primary review",
      tumour_type: { oncotree_code: null },
      updated: 1643961518,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["NIST8392"]',
      gene_panel_titles:
        '["GPM.cerebellar_hypoplasia", "PanelApp Epileptic_encephalopathy", "Imported gene panel - Paediatric Opthalmic.txt: Demo", "Familial breast cancer - LowEvidence: Demo", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 1,
      has_some_obsolete_phenotypes: 0,
      metadata_values: '{"345": "Male", "572": "British"}',
      neoplastic_cellularity: null,
      patient_id: 6171,
      phenotype_names:
        '["Abnormality of the orbital region", "Smooth philtrum", "Hearing abnormality", "Retinopathy", "Hemangioma", "Large eyes", "Migraine with aura", "Truncal ataxia", "Headache", "Arterial thrombosis", "Cystoid macular degeneration", "Venous malformation", "Narcolepsy", "Breast aplasia"]',
      phenotype_summary: null,
      protocol_name: null,
      reference: "HG002",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Final review pending",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["NIST8392"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp Epileptic_encephalopathy", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 6170,
      phenotype_names:
        '["Glaucoma", "Cataract", "Hypertension", "Colon cancer", "Intestinal polyp", "Abnormality of the prostate", "Arrhythmia"]',
      phenotype_summary: null,
      protocol_name: null,
      reference: "HG003",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Primary review",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: null,
      gene_panel_titles:
        '["Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 16108,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "Test Sample 1",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Sample registered",
      tumour_type: { oncotree_code: null },
      updated: 1498469618,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["Family 1"]',
      gene_panel_titles:
        '["GPM.cerebellar_hypoplasia", "Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 8743,
      phenotype_names: '["Cerebellar cortical atrophy"]',
      phenotype_summary: null,
      protocol_name: null,
      reference: "Test patient 1",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Sample registered",
      tumour_type: { oncotree_code: null },
      updated: 1469010743,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["DemoPed"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp - Cerebellar_hypoplasia", "GPM.cerebellar_hypoplasia", "PCH_exomiser_OMIM_top_genes: Demo", "Cerebellar hypoplasia - High Evidence (demo)"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 1,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 526,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "Demo_Father",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Ready for review",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["Family 1"]',
      gene_panel_titles:
        '["Cerebellar hypoplasia - High Evidence (demo)", "OMIM Morbid genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values: null,
      neoplastic_cellularity: null,
      patient_id: 8742,
      phenotype_names: null,
      phenotype_summary: null,
      protocol_name: null,
      reference: "Test patient 3",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Sample registered",
      tumour_type: { oncotree_code: null },
      updated: 1469010743,
    },
    {
      annotation_sources:
        '[{"type": "data", "value": "0.3", "attribute": "EXAC_EXOMES"}, {"type": "data", "value": "81", "attribute": "ENSEMBL"}, {"type": "data", "value": "81", "attribute": "VEP_CACHE"}]',
      baitset: null,
      cnv_analysis: "Not requested",
      family_refs: '["LP2000274-DNA_G11"]',
      gene_panel_titles:
        '["PGx genes: Demo", "PanelApp - Cerebellar_hypoplasia", "PanelApp Epileptic_encephalopathy", "DDG2P_27Apr15", "Epilepsy Plus V0.8 - High evidence genes: Demo"]',
      has_auto_acmg_suggestions: 0,
      has_automated_annotation: 0,
      has_reports: 0,
      has_some_obsolete_phenotypes: 0,
      metadata_values:
        '{"542": "Epileptic encephalopathy, Infantile spasms, Global developmental delay"}',
      neoplastic_cellularity: null,
      patient_id: 455,
      phenotype_names:
        '["Global developmental delay", "Infantile spasms", "Epileptic encephalopathy"]',
      phenotype_summary:
        "Epileptic encephalopathy, Infantile spasms, Global developmental delay",
      protocol_name: null,
      reference: "LP2000274-DNA_G11",
      reports_count: 0,
      reports_with_variants_count: 0,
      reports_without_variants_count: 0,
      sample_type_name: null,
      status: "Primary review",
      tumour_type: { oncotree_code: null },
      updated: 1643919247,
    },
  ],
};

export default genePanelPatientsResponse;
