export const mockProjectIRsResponse = {
  audit: {},
  patients: { "127": { mitosample: "Primary review" } },
  irs: [
    {
      status: "DELETED",
      pending_files: 0,
      reference: "mitosample-1",
      created: "2022-02-28T10:32:30",
      id: 93,
      can_delete: { msg: "Request has already been deleted", can_delete: 0 },
      user_email: "joe.bloggs@congenica.com",
      last_audit: {
        interpretation_request_id: 93,
        created: "2022-02-28T15:12:35",
        status: "DELETED",
        updated: "2022-02-28T15:12:35",
        info: "Deleted by user: joe.bloggs@congenica.com for reason: Ran on older version of the system",
        public: 0,
        interpretation_request_audit_id: 233,
      },
    },
    {
      reference: "mitosample-2",
      created: "2022-02-28T15:13:31",
      id: 126,
      can_delete: { msg: "Request has already been deleted", can_delete: 0 },
      user_email: "joe.bloggs@congenica.com",
      last_audit: {
        interpretation_request_id: 126,
        created: "2022-02-28T15:47:07",
        status: "DELETED",
        updated: "2022-02-28T15:47:07",
        info: "Deleted by user: joe.bloggs@congenica.com for reason: Ref data issue",
        interpretation_request_audit_id: 290,
        public: 0,
      },
      status: "DELETED",
      pending_files: 0,
    },
    {
      user_email: "joe.bloggs@congenica.com",
      last_audit: {
        created: "2022-02-28T17:15:18",
        interpretation_request_id: 127,
        status: "FINISHED_PROCESSING",
        updated: "2022-02-28T17:15:18",
        info: null,
        public: 1,
        interpretation_request_audit_id: 347,
      },
      reference: "mitosample-3",
      can_delete: { msg: "", can_delete: 1 },
      id: 127,
      created: "2022-02-28T15:47:37",
      status: "FINISHED_PROCESSING",
      pending_files: 0,
    },
  ],
  data_files: {
    "127": {
      "45": {
        uploader: "Joe Bloggs",
        updated: "2022-02-28T15:51:50",
        type: "vcf",
        patient_ref: "mitosample",
        id: 45,
        status: "Uploaded",
        transfer_progress: null,
        transfer_error: null,
        name: "/data/source-test-data/mitosample/mitosample.vcf.gz",
        size: 1553450,
        patient_id: 10787,
        subtype: "snv",
      },
    },
  },
};
