export const mockJurisdictions = {
  jurisdictions: [
    { display_jurisdiction: "FDA and/or NCCN", jurisdiction: "USA" },
    {
      display_jurisdiction: "EMA and/or ESMO",
      jurisdiction: "EU",
      default_jurisdiction: true,
    },
    { jurisdiction: "England", display_jurisdiction: "MHRA and/or NICE" },
    { display_jurisdiction: "MHRA and/or SMC", jurisdiction: "Scotland" },
  ],
};
