import { SYSTEM_ENSEMBL_VERSION } from "tests/fixtures";

const genePanelUploadInfo = {
  gene_panel: {
    created: 1679499108,
    description: "File import: mock-gene-panel-file.csv",
    file_blob:
      "chr,start,stop,gene,transcript,autosomal_recessive,autosomal_dominant,x_linked,condition\r\n,,,AARS,,,,,",
    file_name: "mock-gene-panel-file.csv",
    genes_not_found_data: null,
    project_id: 7,
    temp_gene_panel_id: 1234,
    title: "Imported gene panel - mock-gene-panel-file.csv",
    updated: 1679499108,
    uploaded_total_rows: 1,
    ensembl_version: SYSTEM_ENSEMBL_VERSION,
  },
};

export default genePanelUploadInfo;
