import { USER_ID_SYSTEM_USER } from "tests/fixtures";

const mockProjectUsers = {
  project_users: [
    {
      user_id: 427,
      authentication_provider_name: null,
      visibility_level_id: null,
      fullname: "projectadmin",
      authentication_provider_id: null,
      admin: 0,
      roles: null,
      lastlogin: 1600254462,
      access_level: "admin",
      email: "projectadmin@test.com",
      visibility_level_description: null,
      visibility_level_name: null,
    },
    {
      access_level: "write",
      lastlogin: 1645781436,
      roles:
        '[{"name": "curator", "label": "Clinical data curator", "description": "creates patient records and modifies clinical information"}, {"name": "assigner", "label": "Analyst", "description": "assigns pathogenicity and contribution to phenotype of the variants"}]',
      admin: 0,
      authentication_provider_id: null,
      fullname: "TEST",
      authentication_provider_name: null,
      visibility_level_id: "1",
      user_id: 459,
      visibility_level_description: "Level 1 description",
      visibility_level_name: "Level 1",
      email: "test@test.com",
    },
    {
      visibility_level_id: "1",
      authentication_provider_name: null,
      user_id: 426,
      fullname: "superadmin",
      admin: 1,
      authentication_provider_id: null,
      access_level: "write",
      roles:
        '[{"name": "curator", "label": "Clinical data curator", "description": "creates patient records and modifies clinical information"}, {"name": "assigner", "label": "Analyst", "description": "assigns pathogenicity and contribution to phenotype of the variants"}]',
      lastlogin: 1679904740,
      visibility_level_description: "Level 1 description",
      visibility_level_name: "Level 1",
      email: "superadmin@test.com",
    },
  ],
  inherited_users: [
    {
      admin: 0,
      lastlogin: null,
      roles:
        '[{"name": "assigner", "label": "Analyst", "description": "assigns pathogenicity and contribution to phenotype of the variants"}]',
      access_level: "read",
      project_code: "Congenica",
      user_id: USER_ID_SYSTEM_USER,
      visibility_level_id: "1",
      fullname: "System User",
      email: "system-user@sapientia.co.uk",
      visibility_level_name: "Visibility level 1",
      visibility_level_description:
        "can view all patients with visibility levels 1 and 2",
    },
  ],
};

export default mockProjectUsers;
